import React from 'react';

import { useQueryGARInstitutions } from 'gql/gar';
import { useQueryOrganizations } from 'gql/organization';

import Upsert from './Upsert';

const UpsertContainer = (props) => {
  const { data: dataOrg } = useQueryOrganizations();
  const { data: dataInst } = useQueryGARInstitutions();
  return (
    <Upsert
      institutions={dataInst?.GARInstitutions}
      organizations={dataOrg?.organizations}
      {...props}
    />
  );
};

export default UpsertContainer;
