import { gql, useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';

const QUERY_TRANSLATIONS = gql`
  {
    translations {
      id
      key
      en
      fr
      nl
      it
      isFlagged
      isSynced
    }
  }
`;

export function useQueryTranslations() {
  const { loading, data, ...props } = useQuery(QUERY_TRANSLATIONS);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

const QUERY_TRANSLATION = gql`
  query translation($translationId: ID!) {
    translation(translationId: $translationId) {
      id
      key
      en
      fr
      nl
      it
      isFlagged
      isSynced
    }
  }
`;

export function useQueryTranslation(options) {
  const { loading, data, ...props } = useQuery(QUERY_TRANSLATION, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const TRANSLATION_CREATE = gql`
  mutation TranslationCreate(
    $key: String!
    $en: String
    $fr: String
    $nl: String
    $it: String
    $isFlagged: Boolean
  ) {
    translationCreate(
      key: $key
      en: $en
      fr: $fr
      nl: $nl
      it: $it
      isFlagged: $isFlagged
    ) {
      id
    }
  }
`;

export function useMutationTranslationCreate() {
  const [mutation] = useMutation(TRANSLATION_CREATE);
  return useCallback((variables) => mutation({ variables }), [mutation]);
}

export const TRANSLATION_UPDATE = gql`
  mutation TranslationUpdate(
    $translationId: ID!
    $key: String!
    $en: String
    $fr: String
    $nl: String
    $it: String
    $isFlagged: Boolean
  ) {
    translationUpdate(
      translationId: $translationId
      key: $key
      en: $en
      fr: $fr
      nl: $nl
      it: $it
      isFlagged: $isFlagged
    ) {
      id
    }
  }
`;

export function useMutationTranslationUpdate() {
  const [mutation] = useMutation(TRANSLATION_UPDATE);
  return useCallback((variables) => mutation({ variables }), [mutation]);
}

export const TRANSLATION_SYNC = gql`
  mutation TranslationSync {
    translationSync
  }
`;

export function useMutationTranslationSync() {
  const [mutation] = useMutation(TRANSLATION_SYNC);
  return useCallback(() => mutation(), [mutation]);
}

export const TRANSLATION_DELETE = gql`
  mutation TranslationDelete($translationId: ID!) {
    translationDelete(translationId: $translationId)
  }
`;

export function useMutationTranslationDelete() {
  const [mutation] = useMutation(TRANSLATION_DELETE);
  return useCallback((variables) => mutation({ variables }), [mutation]);
}
