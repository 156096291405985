import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useMutationAuthResendVerification } from 'gql/auth';

import Verification from './Verification';

const VerificationContainer = () => {
  const history = useHistory();
  const mutationAuthResendVerification = useMutationAuthResendVerification();
  const handleVerificationResend = useCallback(
    async (values) => {
      try {
        await mutationAuthResendVerification(values);
        message.success('The verification email has been resent.');
        history.push('/auth/local');
      } catch (err) {
        message.error(
          'An error occurred while resending the verification email.',
        );
      }
    },
    [history, mutationAuthResendVerification],
  );
  return <Verification handleVerificationResend={handleVerificationResend} />;
};

export default VerificationContainer;
