import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useMutationSAMLCreate } from 'gql/saml';

import Create from './Create';

const CreateContainer = () => {
  const history = useHistory();
  const mutationSAMLCreate = useMutationSAMLCreate();
  const handleCreate = useCallback(
    async ({ profile, configuration, ...values }) => {
      try {
        const { data } = await mutationSAMLCreate({
          ...values,
          profile: JSON.parse(profile),
          configuration: JSON.parse(configuration),
        });
        message.success('The SAML strategy has been created.');
        history.push(`/auth/saml/read/${data.samlCreate.id}`);
      } catch (err) {
        message.error('An error occurred while creating the SAML strategy.');
      }
    },
    [history, mutationSAMLCreate],
  );
  return <Create handleCreate={handleCreate} />;
};

export default CreateContainer;
