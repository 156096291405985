import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useQueryGAR, useMutationGARUpdate } from 'gql/gar';

import Update from './Update';

const UpdateContainer = () => {
  const history = useHistory();
  const { id } = useParams();
  const { data } = useQueryGAR({
    variables: {
      garId: id,
    },
  });
  const mutationGARUpdate = useMutationGARUpdate();
  const handleUpdate = useCallback(
    async (values) => {
      try {
        await mutationGARUpdate({
          ...values,
          garId: data.GAR.id,
        });
        message.success('The GAR subscription has been updated.');
        history.push(`/partners/gar/read/${data.GAR.id}`);
      } catch (err) {
        message.error('An error occurred while updating the GAR subscription.');
      }
    },
    [data, history, mutationGARUpdate],
  );
  return <Update data={data?.GAR} handleUpdate={handleUpdate} />;
};

export default UpdateContainer;
