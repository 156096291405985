import { SendOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input, Switch } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const Upsert = ({ data, handleSubmit }) => (
  <Form
    initialValues={{
      key: data.key || '',
      en: data.en || '',
      fr: data.fr || '',
      nl: data.nl || '',
      it: data.it || '',
      isFlagged: data.isFlagged || false,
    }}
    layout="vertical"
    onFinish={handleSubmit}
  >
    <p>
      <Alert
        message="Information"
        description="Translations are handled by i18next. To insert values, surround their identifier by curly brackets: {{values_identifier}}. To reference another translation, use the '$t' function: $t(translation_key). For more options visit: https://www.i18next.com/translation-function/essentials."
        type="info"
        showIcon
      />
    </p>
    <Form.Item label="Translation key" name="key" rules={[{ required: true }]}>
      <Input placeholder="Course/Dashboard/title" />
    </Form.Item>
    <Form.Item label="English translation" name="en">
      <Input.TextArea autoSize />
    </Form.Item>
    <Form.Item label="French translation" name="fr">
      <Input.TextArea autoSize />
    </Form.Item>
    <Form.Item label="Dutch translation" name="nl">
      <Input.TextArea autoSize />
    </Form.Item>
    <Form.Item label="Italian translation" name="it">
      <Input.TextArea autoSize />
    </Form.Item>
    <Form.Item
      label="Flag this translation"
      name="isFlagged"
      valuePropName="checked"
    >
      <Switch />
    </Form.Item>
    <Button htmlType="submit" icon={<SendOutlined />} type="primary">
      Submit
    </Button>
  </Form>
);

Upsert.propTypes = {
  data: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
};

Upsert.defaultProps = {
  data: {},
};

export default Upsert;
