import {
  ArrowLeftOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { Button, Col, Descriptions, Divider, Row, Spin, Tag } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import * as dayjs from 'dayjs';

const Read = ({ data }) =>
  !data ? (
    <Spin />
  ) : (
    <>
      <Row gutter={16}>
        <Col>
          <Link to="/partners/tnecanope">
            <Button icon={<ArrowLeftOutlined />}>Back</Button>
          </Link>
        </Col>
        <Col flex="auto">
          <h1>{`View a TNE Canope order - ${data.id}`}</h1>
        </Col>
      </Row>
      <Divider />
      <Descriptions bordered column={1}>
        <Descriptions.Item label="Name of the school">
          {data.data.commentaireCommande.nomEtablissement}
        </Descriptions.Item>
        <Descriptions.Item label="Start of the subscription">
          {dayjs(data.data.debutValidite).format('YYYY-MM-DD')}
        </Descriptions.Item>
        <Descriptions.Item label="# Teachers">
          {data.data.nbLicenceEnseignant}
        </Descriptions.Item>
        <Descriptions.Item label="# Students">
          {data.data.nbLicenceEleve}
        </Descriptions.Item>
        <Descriptions.Item label="Error">{data.error}</Descriptions.Item>
        <Descriptions.Item label="Is GAR done?">
          {data.isGARDone ? (
            <Tag icon={<CheckCircleOutlined />} color="success">
              Yes
            </Tag>
          ) : (
            <Tag icon={<CloseCircleOutlined />} color="error">
              No
            </Tag>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Is TNE done?">
          {data.isTNEDone ? (
            <Tag icon={<CheckCircleOutlined />} color="success">
              Yes
            </Tag>
          ) : (
            <Tag icon={<CloseCircleOutlined />} color="error">
              No
            </Tag>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Is done?">
          {data.isDone ? (
            <Tag icon={<CheckCircleOutlined />} color="success">
              Yes
            </Tag>
          ) : (
            <Tag icon={<CloseCircleOutlined />} color="error">
              No
            </Tag>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="# Retries">{data.retries}</Descriptions.Item>
        <Descriptions.Item label="Data">
          <pre>
            <code>{JSON.stringify(data.data, null, 2)}</code>
          </pre>
        </Descriptions.Item>
      </Descriptions>
    </>
  );

Read.propTypes = {
  data: PropTypes.object,
};

Read.defaultProps = {
  data: undefined,
};

export default Read;
