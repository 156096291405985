import { ArrowLeftOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Popconfirm, Result, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const Sync = ({ handleSync }) => (
  <>
    <Row gutter={16}>
      <Col>
        <Link to="/translations/">
          <Button icon={<ArrowLeftOutlined />}>Back</Button>
        </Link>
      </Col>
      <Col flex="auto">
        <h1>Sync the translations</h1>
      </Col>
    </Row>
    <Divider />
    <Result
      extra={
        <Popconfirm
          okText="Yes"
          onConfirm={handleSync}
          title="Last chance. Are you sure?"
        >
          <Button icon={<SyncOutlined />} type="primary">
            Sync the translations
          </Button>
        </Popconfirm>
      }
      icon={<SyncOutlined />}
      status="success"
      title="Are you sure you want to sync the translations"
    />
  </>
);

Sync.propTypes = {
  handleSync: PropTypes.func.isRequired,
};

export default Sync;
