import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useQueryGAR, useMutationGARDelete } from 'gql/gar';

import Delete from './Delete';

const DeleteContainer = () => {
  const history = useHistory();
  const { id } = useParams();
  const { data } = useQueryGAR({
    variables: {
      garId: id,
    },
  });
  const mutationGARDelete = useMutationGARDelete();
  const handleDelete = useCallback(async () => {
    try {
      await mutationGARDelete({
        garId: data.GAR.id,
      });
      message.success('The GAR subscription has been deleted.');
      history.push('/gar');
    } catch (err) {
      message.error('An error occurred while deleting the GAR subscription.');
    }
  }, [data, history, mutationGARDelete]);
  return <Delete data={data?.GAR} handleDelete={handleDelete} />;
};

export default DeleteContainer;
