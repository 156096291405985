import { gql, useQuery } from '@apollo/client';

const QUERY_TNECANOPES = gql`
  {
    TNECanopes {
      id
      data
      error
      retries
      isGARDone
      isTNEDone
      isDone
      createdAt
    }
  }
`;

export function useQueryTNECanopes() {
  const { loading, data, ...props } = useQuery(QUERY_TNECANOPES);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

const QUERY_TNECANOPE = gql`
  query tnecanope($tnecanopeId: ID!) {
    TNECanope(tnecanopeId: $tnecanopeId) {
      id
      data
      error
      retries
      isGARDone
      isTNEDone
      isDone
      createdAt
    }
  }
`;

export function useQueryTNECanope(options) {
  const { loading, data, ...props } = useQuery(QUERY_TNECANOPE, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}
