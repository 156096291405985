import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useMutationEduGAINUpdateAll } from 'gql/edugain';

import UpdateAll from './UpdateAll';

const UpdateAllContainer = () => {
  const history = useHistory();
  const mutationEduGAINUpdateAll = useMutationEduGAINUpdateAll();
  const handleUpdateAll = useCallback(async () => {
    try {
      await mutationEduGAINUpdateAll();
      message.success('All eduGAIN strategies have been updated.');
      history.push('/auth/edugain');
    } catch (err) {
      message.error('An error occurred while updating all eduGAIN strategies.');
    }
  }, [history, mutationEduGAINUpdateAll]);
  return <UpdateAll handleUpdateAll={handleUpdateAll} />;
};

export default UpdateAllContainer;
