import {
  ArrowLeftOutlined,
  UploadOutlined,
  KeyOutlined,
  FlagOutlined,
} from '@ant-design/icons';
import { Button, Col, Divider, Result, Row, Upload } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import storeJS from 'store';

const Import = ({ handleImportAll, handleImportKeys }) => (
  <>
    <Row gutter={16}>
      <Col>
        <Link to="/translations">
          <Button icon={<ArrowLeftOutlined />}>Back</Button>
        </Link>
      </Col>
      <Col flex="auto">
        <h1>Import translation keys</h1>
      </Col>
    </Row>
    <Divider />
    <Result
      extra={
        <Upload
          accept=".json"
          action={`${process.env.REACT_APP_API_URL}/api/translations/import-all`}
          headers={{
            Authorization: `Bearer ${storeJS.get('token')}`,
          }}
          onChange={handleImportAll}
          showUploadList={false}
        >
          <Button>
            <UploadOutlined /> Click to Upload
          </Button>
        </Upload>
      }
      icon={<FlagOutlined />}
      status="info"
      subTitle="Import translations from a JSON file to create missing translations and update existing ones if a newer version is found."
      title="Select the file containing the translations."
    />
    <Divider />
    <Result
      extra={
        <Upload
          accept=".json"
          action={`${process.env.REACT_APP_API_URL}/api/translations/import-keys`}
          headers={{
            Authorization: `Bearer ${storeJS.get('token')}`,
          }}
          onChange={handleImportKeys}
          showUploadList={false}
        >
          <Button>
            <UploadOutlined /> Click to Upload
          </Button>
        </Upload>
      }
      icon={<KeyOutlined />}
      status="info"
      subTitle="Import translation keys from a JSON file to create missing translation keys and flag obsolete translation keys."
      title="Select the file containing the translation keys."
    />
  </>
);

Import.propTypes = {
  handleImportAll: PropTypes.func.isRequired,
  handleImportKeys: PropTypes.func.isRequired,
};

export default Import;
