import { EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row, Space, Table } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import {
  sortText,
  searchText,
  sortBool,
  searchBool,
  renderBool,
} from 'lib/table';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    ...sortText('name'),
    ...searchText('name'),
  },
  {
    title: '# Licenses',
    dataIndex: 'nbLicenses',
    key: 'nbLicenses',
    ...sortText('nbLicenses'),
    ...searchText('nbLicenses'),
  },
  {
    title: 'Is TNE?',
    dataIndex: 'isTNE',
    key: 'isTNE',
    width: 160,
    ...sortBool('isTNE'),
    ...searchBool('isTNE'),
    ...renderBool(),
  },
  {
    title: 'Organization',
    key: 'organization',
    // eslint-disable-next-line react/prop-types
    render: ({ organization }) => (organization ? organization.name : ''),
  },
  {
    title: '',
    key: 'read',
    width: 100,
    // eslint-disable-next-line react/prop-types
    render: ({ id }) => (
      <Link to={`/partners/gar/read/${id}`}>
        <Button icon={<EyeOutlined />}>View</Button>
      </Link>
    ),
  },
];

const List = ({ data }) => (
  <>
    <Row gutter={16}>
      <Col flex="auto">
        <h1>List all GAR subscriptions</h1>
      </Col>
      <Col>
        <Space>
          <Link to="/partners/gar/institutions">
            <Button icon={<EyeOutlined />} type="default">
              View the institutions
            </Button>
          </Link>
          <Link to="/partners/gar/subscriptions">
            <Button icon={<EyeOutlined />} type="default">
              View the remote subscriptions
            </Button>
          </Link>
          <Link to="/partners/gar/create">
            <Button icon={<PlusOutlined />} type="primary">
              Create a new GAR subscription
            </Button>
          </Link>
        </Space>
      </Col>
    </Row>
    <Divider />
    <Table columns={columns} dataSource={data} loading={!data} />
  </>
);

List.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

List.defaultProps = {
  data: undefined,
};

export default List;
