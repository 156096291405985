import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useQueryPlan, useMutationPlanDelete } from 'gql/plan';

import Delete from './Delete';

const DeleteContainer = () => {
  const history = useHistory();
  const { id } = useParams();
  const { data } = useQueryPlan({
    variables: {
      planId: id,
    },
  });
  const mutationPlanDelete = useMutationPlanDelete();
  const handleDelete = useCallback(async () => {
    try {
      await mutationPlanDelete({
        planId: data.plan.id,
      });
      message.success('The plan has been deleted.');
      history.push('/plans');
    } catch (err) {
      message.error('An error occurred while deleting the plan.');
    }
  }, [data, history, mutationPlanDelete]);
  return <Delete data={data?.plan} handleDelete={handleDelete} />;
};

export default DeleteContainer;
