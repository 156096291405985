import { BugOutlined, CheckSquareOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row, Space } from 'antd';
import React from 'react';

const Internal = () => (
  <>
    <Row gutter={16}>
      <Col flex="auto">
        <h1>Internal</h1>
      </Col>
      <Col>
        <Space>
          <Button
            href={`${process.env.REACT_APP_API_URL}/api/auth/internal/login?debug=true`}
            icon={<BugOutlined />}
          >
            Debug
          </Button>
          <Button
            href={`${process.env.REACT_APP_API_URL}/api/auth/internal/login`}
            icon={<CheckSquareOutlined />}
            type="primary"
          >
            Test
          </Button>
        </Space>
      </Col>
    </Row>
    <Divider />
    <p>
      Google Console:{' '}
      <a
        href="https://console.developers.google.com/apis/credentials/oauthclient/1077706158730-8an3c09i7fli76eda349qiiggnnmo1mm.apps.googleusercontent.com?project=wooflash"
        target="blank"
      >
        https://console.developers.google.com/apis/credentials/oauthclient/1077706158730-8an3c09i7fli76eda349qiiggnnmo1mm.apps.googleusercontent.com?project=wooflash
      </a>
      .
    </p>
  </>
);

export default Internal;
