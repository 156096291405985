import { gql, useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';

const QUERY_LTIV1P1S = gql`
  {
    ltisv1p1 {
      id
      organizationId
      name
      specifier
      domains
      organization {
        id
        name
      }
    }
  }
`;

export function useQueryLTIv1p1s() {
  const { loading, data, ...props } = useQuery(QUERY_LTIV1P1S);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

const QUERY_LTIV1P1 = gql`
  query ltiv1p1($ltiId: ID!) {
    ltiv1p1(ltiId: $ltiId) {
      id
      organizationId
      name
      specifier
      domains
      adminEmail
      accessKeyId
      secretAccessKey
      organization {
        id
        name
      }
    }
  }
`;

export function useQueryLTIv1p1(options) {
  const { loading, data, ...props } = useQuery(QUERY_LTIV1P1, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const LTIV1P1_CREATE = gql`
  mutation LTIv1p1Create(
    $organizationId: ID
    $name: String!
    $specifier: String!
    $domains: [String!]!
    $adminEmail: String
  ) {
    ltiv1p1Create(
      organizationId: $organizationId
      name: $name
      specifier: $specifier
      domains: $domains
      adminEmail: $adminEmail
    ) {
      id
    }
  }
`;

export function useMutationLTIv1p1Create() {
  const [mutation] = useMutation(LTIV1P1_CREATE);
  return useCallback((variables) => mutation({ variables }), [mutation]);
}

export const LTIV1P1_UPDATE = gql`
  mutation LTIv1p1Update(
    $ltiId: ID!
    $organizationId: ID
    $name: String
    $specifier: String
    $domains: [String!]
    $adminEmail: String
  ) {
    ltiv1p1Update(
      ltiId: $ltiId
      organizationId: $organizationId
      name: $name
      specifier: $specifier
      domains: $domains
      adminEmail: $adminEmail
    ) {
      id
    }
  }
`;

export function useMutationLTIv1p1Update() {
  const [mutation] = useMutation(LTIV1P1_UPDATE);
  return useCallback((variables) => mutation({ variables }), [mutation]);
}

export const LTIV1P1_DELETE = gql`
  mutation LTIv1p1Delete($ltiId: ID!) {
    ltiv1p1Delete(ltiId: $ltiId)
  }
`;

export function useMutationLTIv1p1Delete() {
  const [mutation] = useMutation(LTIV1P1_DELETE);
  return useCallback((variables) => mutation({ variables }), [mutation]);
}
