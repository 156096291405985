import { Form } from 'antd';
import React from 'react';

import { useQueryOrganizations } from 'gql/organization';
import { useQueryPlans } from 'gql/plan';

import Upsert from './Upsert';

const UpsertContainer = (props) => {
  const { data: dataOrganizations } = useQueryOrganizations();
  const { data: dataPlans } = useQueryPlans();
  const [form] = Form.useForm();
  return (
    <Upsert
      form={form}
      organizations={dataOrganizations?.organizations}
      plans={dataPlans?.plans}
      {...props}
    />
  );
};

export default UpsertContainer;
