import React from 'react';
import { useParams } from 'react-router-dom';

import { useQueryTNECanope } from 'gql/tnecanope';
import Read from './Read';

const ReadContainer = () => {
  const { id } = useParams();
  const { data } = useQueryTNECanope({
    variables: {
      tnecanopeId: id,
    },
  });
  return <Read data={data?.TNECanope} />;
};

export default ReadContainer;
