import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useMutationPlanUpdate, useQueryPlan } from 'gql/plan';

import Update from './Update';

const UpdateContainer = () => {
  const history = useHistory();
  const { id } = useParams();
  const { data } = useQueryPlan({
    variables: {
      planId: id,
    },
  });
  const mutationPlanUpdate = useMutationPlanUpdate();
  const handleUpdate = useCallback(
    async (values) => {
      try {
        await mutationPlanUpdate({
          ...values,
          planId: data.plan.id,
        });
        message.success('The plan has been updated.');
        history.push(`/plans/read/${data.plan.id}`);
      } catch (err) {
        message.error('An error occurred while updating the plan.');
      }
    },
    [data, history, mutationPlanUpdate],
  );
  return <Update data={data?.plan} handleUpdate={handleUpdate} />;
};

export default UpdateContainer;
