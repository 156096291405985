import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useMutationMoodleCreate } from 'gql/moodle';

import Create from './Create';

const CreateContainer = () => {
  const history = useHistory();
  const mutationMoodleCreate = useMutationMoodleCreate();
  const handleCreate = useCallback(
    async (values) => {
      try {
        const { data } = await mutationMoodleCreate(values);
        message.success('The Moodle service has been created.');
        history.push(`/lms/moodle/read/${data.moodleCreate.id}`);
      } catch (err) {
        message.error('An error occurred while creating the Moodle service.');
      }
    },
    [history, mutationMoodleCreate],
  );
  return <Create handleCreate={handleCreate} />;
};

export default CreateContainer;
