import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Popconfirm, Result, Row, Spin } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const Delete = ({ data, handleDelete }) =>
  !data ? (
    <Spin />
  ) : (
    <>
      <Row gutter={16}>
        <Col>
          <Link to={`/auth/cas/read/${data.id}`}>
            <Button icon={<ArrowLeftOutlined />}>Back</Button>
          </Link>
        </Col>
        <Col flex="auto">
          <h1>{`Delete a CAS strategy - ${data.name}`}</h1>
        </Col>
      </Row>
      <Divider />
      <Result
        extra={
          <Popconfirm
            okText="Yes"
            onConfirm={handleDelete}
            title="Last chance. Are you sure?"
          >
            <Button icon={<DeleteOutlined />} type="danger">
              Delete this CAS strategy
            </Button>
          </Popconfirm>
        }
        icon={<DeleteOutlined />}
        status="error"
        title={`Are you sure you want to delete "${data.name}"?`}
      />
    </>
  );

Delete.propTypes = {
  data: PropTypes.object,
  handleDelete: PropTypes.func.isRequired,
};

Delete.defaultProps = {
  data: undefined,
};

export default Delete;
