import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  useMutationOrganizationUpdate,
  useQueryOrganization,
} from 'gql/organization';

import Update from './Update';

const UpdateContainer = () => {
  const history = useHistory();
  const { id } = useParams();
  const { data } = useQueryOrganization({
    variables: {
      organizationId: id,
    },
  });
  const mutationOrganizationUpdate = useMutationOrganizationUpdate();
  const handleUpdate = useCallback(
    async (values) => {
      try {
        await mutationOrganizationUpdate({
          ...values,
          organizationId: data.organization.id,
        });
        message.success('The organization has been updated.');
        history.push(`/organizations/read/${data.organization.id}`);
      } catch (err) {
        message.error('An error occurred while updating the organization.');
      }
    },
    [data, history, mutationOrganizationUpdate],
  );
  return <Update data={data?.organization} handleUpdate={handleUpdate} />;
};

export default UpdateContainer;
