import { gql, useQuery } from '@apollo/client';

const QUERY_SSOS = gql`
  {
    SSOs {
      id
      name
      specifier
      type
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export function useQuerySSOs() {
  const { loading, data, ...props } = useQuery(QUERY_SSOS);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}
