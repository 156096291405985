import {
  ArrowLeftOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Descriptions,
  Divider,
  Row,
  Space,
  Spin,
  Tag,
} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const Read = ({ data }) =>
  !data ? (
    <Spin />
  ) : (
    <>
      <Row gutter={16}>
        <Col>
          <Link to="/translations">
            <Button icon={<ArrowLeftOutlined />}>Back</Button>
          </Link>
        </Col>
        <Col flex="auto">
          <h1>{`View a translation - ${data.key}`}</h1>
        </Col>
        <Col>
          <Space>
            <Link to={`/translations/update/${data.id}`}>
              <Button icon={<EditOutlined />} type="primary">
                Update
              </Button>
            </Link>
            <Link to={`/translations/duplicate/${data.id}`}>
              <Button icon={<CopyOutlined />}>Duplicate</Button>
            </Link>
            <Link to={`/translations/delete/${data.id}`}>
              <Button icon={<DeleteOutlined />} type="danger">
                Delete
              </Button>
            </Link>
          </Space>
        </Col>
      </Row>
      <Divider />
      <Descriptions bordered column={1}>
        <Descriptions.Item label="Translation key">
          {data.key}
        </Descriptions.Item>
        <Descriptions.Item label="English">
          <pre>
            <code>{data.en}</code>
          </pre>
        </Descriptions.Item>
        <Descriptions.Item label="French">
          <pre>
            <code>{data.fr}</code>
          </pre>
        </Descriptions.Item>
        <Descriptions.Item label="Dutch">
          <pre>
            <code>{data.nl}</code>
          </pre>
        </Descriptions.Item>
        <Descriptions.Item label="Italian">
          <pre>
            <code>{data.it}</code>
          </pre>
        </Descriptions.Item>
        <Descriptions.Item label="Is flagged?">
          {data.isFlagged ? (
            <Tag icon={<CheckCircleOutlined />} color="success">
              Yes
            </Tag>
          ) : (
            <Tag icon={<CloseCircleOutlined />} color="error">
              No
            </Tag>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Is synced?">
          {data.isSynced ? (
            <Tag icon={<CheckCircleOutlined />} color="success">
              Yes
            </Tag>
          ) : (
            <Tag icon={<CloseCircleOutlined />} color="error">
              No
            </Tag>
          )}
        </Descriptions.Item>
      </Descriptions>
    </>
  );

Read.propTypes = {
  data: PropTypes.object,
};

Read.defaultProps = {
  data: undefined,
};

export default Read;
