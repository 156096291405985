import { ArrowLeftOutlined, UserAddOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Popconfirm, Result, Row, Spin } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const Populate = ({ data, handlePopulate }) =>
  !data ? (
    <Spin />
  ) : (
    <>
      <Row gutter={16}>
        <Col>
          <Link to={`/organizations/read/${data.id}`}>
            <Button icon={<ArrowLeftOutlined />}>Back</Button>
          </Link>
        </Col>
        <Col flex="auto">
          <h1>{`Populate an organization - ${data.name}`}</h1>
        </Col>
      </Row>
      <Divider />
      <Result
        extra={
          <Popconfirm
            okText="Yes"
            onConfirm={handlePopulate}
            title="Last chance. Are you sure?"
          >
            <Button icon={<UserAddOutlined />} type="primary">
              Populate this organization
            </Button>
          </Popconfirm>
        }
        icon={<UserAddOutlined />}
        status="success"
        title={`Are you sure you want to populate "${data.name}?`}
      />
    </>
  );

Populate.propTypes = {
  data: PropTypes.object,
  handlePopulate: PropTypes.func.isRequired,
};

Populate.defaultProps = {
  data: undefined,
};

export default Populate;
