const CATEGORIES = [
  {
    key: 'agriculture',
    translation: 'Agriculture',
  },
  {
    key: 'anthropology',
    translation: 'Anthropology',
  },
  {
    key: 'architecture',
    translation: 'Architecture',
  },
  {
    key: 'art',
    translation: 'Art',
  },
  {
    key: 'biology',
    translation: 'Biology',
  },
  {
    key: 'civic-education',
    translation: 'Civic education',
  },
  {
    key: 'computer-science',
    translation: 'Computer science',
  },
  {
    key: 'communication',
    translation: 'Communication',
  },
  {
    key: 'design',
    translation: 'Design',
  },
  {
    key: 'ecology',
    translation: 'Ecology',
  },
  {
    key: 'economics',
    translation: 'Economics',
  },
  {
    key: 'education',
    translation: 'Education',
  },
  {
    key: 'engineering',
    translation: 'Engineering',
  },
  {
    key: 'geology',
    translation: 'Geology',
  },
  {
    key: 'health',
    translation: 'health',
  },
  {
    key: 'history-geography',
    translation: 'History and geography',
  },
  {
    key: 'journalism',
    translation: 'Journalism',
  },
  {
    key: 'language',
    translation: 'Language',
  },
  {
    key: 'law',
    translation: 'Law',
  },
  {
    key: 'literature',
    translation: 'Literature',
  },
  {
    key: 'logic',
    translation: 'Logic',
  },
  {
    key: 'management',
    translation: 'Management',
  },
  {
    key: 'mathematics',
    translation: 'Mathematics',
  },
  {
    key: 'music',
    translation: 'Music',
  },
  {
    key: 'other',
    translation: 'Other',
  },
  {
    key: 'physic-chemistry',
    translation: 'Physic and chemistry',
  },
  {
    key: 'philosophy',
    translation: 'philosophy',
  },
  {
    key: 'political-sciences',
    translation: 'Political sciences',
  },
  {
    key: 'psychology',
    translation: 'Psychology',
  },
  {
    key: 'religion',
    translation: 'Religion',
  },
  {
    key: 'science',
    translation: 'Science',
  },
  {
    key: 'sport',
    translation: 'Sport',
  },
  {
    key: 'social-sciences',
    translation: 'Social sciences',
  },
  {
    key: 'technology',
    translation: 'Technology',
  },
];

export default CATEGORIES;
