import React from 'react';
import { useParams } from 'react-router-dom';

import { useQueryLTIv1p3 } from 'gql/ltiv1p3';

import Read from './Read';

const ReadContainer = () => {
  const { id } = useParams();
  const { data } = useQueryLTIv1p3({
    variables: {
      ltiId: id,
    },
  });
  return <Read data={data?.ltiv1p3} />;
};

export default ReadContainer;
