import React from 'react';
import { useParams } from 'react-router-dom';

import { useQueryOrganization } from 'gql/organization';

import Read from './Read';

const ReadContainer = () => {
  const { id } = useParams();
  const { data } = useQueryOrganization({
    variables: {
      organizationId: id,
    },
  });
  return <Read data={data?.organization} />;
};

export default ReadContainer;
