import { gql, useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';

const QUERY_ORGANIZATIONS = gql`
  {
    organizations {
      id
      planId
      name
      domains
      isPilot
      plan {
        id
        name
      }
    }
  }
`;

export function useQueryOrganizations() {
  const { loading, data, ...props } = useQuery(QUERY_ORGANIZATIONS);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

const QUERY_ORGANIZATION = gql`
  query organization($organizationId: ID!) {
    organization(organizationId: $organizationId) {
      id
      planId
      name
      picture
      domains
      forceSSO
      allowGoogleSSO
      allowMicrosoftSSO
      adminEmail
      supportEmail
      isPilot
      pilotStartDate
      pilotStopDate
      pipeDriveDealLink
      pipeDriveOrganizationLink
      settings
      plan {
        id
        name
      }
      CASs {
        id
        name
      }
      eduGAINs {
        id
        name
      }
      SAMLs {
        id
        name
      }
      moodles {
        id
        name
      }
      ltisv1p1 {
        id
        name
      }
      ltisv1p3 {
        id
        name
      }
      accounts {
        id
        firstName
        lastName
      }
    }
  }
`;

export function useQueryOrganization(options) {
  const { loading, data, ...props } = useQuery(QUERY_ORGANIZATION, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const ORGANIZATION_CREATE = gql`
  mutation OrganizationCreate(
    $planId: ID
    $name: String!
    $domains: [String]
    $forceSSO: Boolean
    $allowGoogleSSO: Boolean
    $allowMicrosoftSSO: Boolean
    $adminEmail: String
    $supportEmail: String
    $isPilot: Boolean
    $pilotStartDate: Date
    $pilotStopDate: Date
    $pipeDriveDealLink: String
    $pipeDriveOrganizationLink: String
    $settings: JSON
  ) {
    organizationCreate(
      planId: $planId
      name: $name
      domains: $domains
      forceSSO: $forceSSO
      allowGoogleSSO: $allowGoogleSSO
      allowMicrosoftSSO: $allowMicrosoftSSO
      adminEmail: $adminEmail
      supportEmail: $supportEmail
      isPilot: $isPilot
      pilotStartDate: $pilotStartDate
      pilotStopDate: $pilotStopDate
      pipeDriveDealLink: $pipeDriveDealLink
      pipeDriveOrganizationLink: $pipeDriveOrganizationLink
      settings: $settings
    ) {
      id
    }
  }
`;

export function useMutationOrganizationCreate() {
  const [mutation] = useMutation(ORGANIZATION_CREATE);
  return useCallback((variables) => mutation({ variables }), [mutation]);
}

export const ORGANIZATION_UPDATE = gql`
  mutation OrganizationUpdate(
    $organizationId: ID!
    $planId: ID
    $name: String
    $domains: [String]
    $forceSSO: Boolean
    $allowGoogleSSO: Boolean
    $allowMicrosoftSSO: Boolean
    $adminEmail: String
    $supportEmail: String
    $isPilot: Boolean
    $pilotStartDate: Date
    $pilotStopDate: Date
    $pipeDriveDealLink: String
    $pipeDriveOrganizationLink: String
    $settings: JSON
  ) {
    organizationUpdate(
      organizationId: $organizationId
      planId: $planId
      name: $name
      domains: $domains
      forceSSO: $forceSSO
      allowGoogleSSO: $allowGoogleSSO
      allowMicrosoftSSO: $allowMicrosoftSSO
      adminEmail: $adminEmail
      supportEmail: $supportEmail
      isPilot: $isPilot
      pilotStartDate: $pilotStartDate
      pilotStopDate: $pilotStopDate
      pipeDriveDealLink: $pipeDriveDealLink
      pipeDriveOrganizationLink: $pipeDriveOrganizationLink
      settings: $settings
    ) {
      id
    }
  }
`;

export function useMutationOrganizationUpdate() {
  const [mutation] = useMutation(ORGANIZATION_UPDATE);
  return useCallback((variables) => mutation({ variables }), [mutation]);
}

export const ORGANIZATION_POPULATE = gql`
  mutation OrganizationPopulation($organizationId: ID!) {
    organizationPopulate(organizationId: $organizationId) {
      id
    }
  }
`;

export function useMutationOrganizationPopulate() {
  const [mutation] = useMutation(ORGANIZATION_POPULATE);
  return useCallback((variables) => mutation({ variables }), [mutation]);
}

export const ORGANIZATION_DELETE = gql`
  mutation OrganizationDelete($organizationId: ID!) {
    organizationDelete(organizationId: $organizationId)
  }
`;

export function useMutationOrganizationDelete() {
  const [mutation] = useMutation(ORGANIZATION_DELETE);
  return useCallback((variables) => mutation({ variables }), [mutation]);
}
