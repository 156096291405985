import React from 'react';
import { useParams } from 'react-router-dom';

import { useQueryCoupon } from 'gql/coupon';
import Read from './Read';

const ReadContainer = () => {
  const { id } = useParams();
  const { data } = useQueryCoupon({
    variables: {
      couponId: id,
    },
  });
  return <Read data={data?.coupon} />;
};

export default ReadContainer;
