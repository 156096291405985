import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Import from './Import';

const ImportContainer = () => {
  const history = useHistory();
  const handleImportAll = useCallback(
    (info) => {
      if (info.file.status === 'done') {
        message.success('The translations have been imported.');
        history.push('/translations');
      } else if (info.file.status === 'error') {
        message.error('An error occurred while importing the translations.');
      }
    },
    [history],
  );
  const handleImportKeys = useCallback(
    (info) => {
      if (info.file.status === 'done') {
        message.success('The translation keys have been imported.');
        history.push('/translations');
      } else if (info.file.status === 'error') {
        message.error(
          'An error occurred while importing the translation keys.',
        );
      }
    },
    [history],
  );
  return (
    <Import
      handleImportAll={handleImportAll}
      handleImportKeys={handleImportKeys}
    />
  );
};

export default ImportContainer;
