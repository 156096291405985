import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useMutationOrganizationCreate } from 'gql/organization';

import Create from './Create';

const CreateContainer = () => {
  const history = useHistory();
  const mutationOrganizationCreate = useMutationOrganizationCreate();
  const handleCreate = useCallback(
    async (values) => {
      try {
        const { data } = await mutationOrganizationCreate(values);
        message.success('The organization has been created.');
        history.push(`/organizations/read/${data.organizationCreate.id}`);
      } catch (err) {
        message.error('An error occurred while creating the organization.');
      }
    },
    [history, mutationOrganizationCreate],
  );
  return <Create handleCreate={handleCreate} />;
};

export default CreateContainer;
