import React from 'react';
import { useParams } from 'react-router-dom';

import { useQueryLTIv1p1 } from 'gql/ltiv1p1';
import Read from './Read';

const ReadContainer = () => {
  const { id } = useParams();
  const { data } = useQueryLTIv1p1({
    variables: {
      ltiId: id,
    },
  });
  return <Read data={data?.ltiv1p1} />;
};

export default ReadContainer;
