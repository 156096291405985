import { ArrowLeftOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Popconfirm, Result, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const UpdateAll = ({ handleUpdateAll }) => (
  <>
    <Row gutter={16}>
      <Col>
        <Link to="/auth/edugain">
          <Button icon={<ArrowLeftOutlined />}>Back</Button>
        </Link>
      </Col>
      <Col flex="auto">
        <h1>Update all eduGAIN strategies</h1>
      </Col>
    </Row>
    <Divider />
    <Result
      extra={
        <Popconfirm
          okText="Yes"
          onConfirm={handleUpdateAll}
          title="Last chance. Are you sure?"
        >
          <Button icon={<ReloadOutlined />} type="primary">
            Update all eduGAIN strategies
          </Button>
        </Popconfirm>
      }
      icon={<ReloadOutlined />}
      status="info"
      subTitle="This action may take a couple of minutes."
      title="Are you sure you want to update all eduGAIN strategies?"
    />
  </>
);

UpdateAll.propTypes = {
  handleUpdateAll: PropTypes.func.isRequired,
};

export default UpdateAll;
