import React from 'react';

import { useQueryOrganizations } from 'gql/organization';

import List from './List';

const ListContainer = () => {
  const { data } = useQueryOrganizations();
  return <List data={data?.organizations} />;
};

export default ListContainer;
