import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useMutationCouponCreate } from 'gql/coupon';

import Create from './Create';

const CreateContainer = () => {
  const history = useHistory();
  const mutationCouponCreate = useMutationCouponCreate();
  const handleCreate = useCallback(
    async (values) => {
      try {
        const { data } = await mutationCouponCreate(values);
        message.success('The coupon has been created.');
        history.push(`/coupons/read/${data.couponCreate.id}`);
      } catch (err) {
        message.error('An error occurred while creating the coupon.');
      }
    },
    [history, mutationCouponCreate],
  );
  return <Create handleCreate={handleCreate} />;
};

export default CreateContainer;
