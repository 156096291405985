import {
  ArrowLeftOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Descriptions,
  Divider,
  Row,
  Space,
  Spin,
  Tag,
} from 'antd';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const Read = ({ data }) =>
  !data ? (
    <Spin />
  ) : (
    <>
      <Row gutter={16}>
        <Col>
          <Link to="/partners/gar">
            <Button icon={<ArrowLeftOutlined />}>Back</Button>
          </Link>
        </Col>
        <Col flex="auto">
          <h1>{`View a GAR subscription - ${data.name}`}</h1>
        </Col>
        <Col>
          <Space>
            <Link to={`/partners/gar/update/${data.id}`}>
              <Button icon={<EditOutlined />} type="primary">
                Update
              </Button>
            </Link>
            <Link to={`/partners/gar/delete/${data.id}`}>
              <Button icon={<DeleteOutlined />} type="danger">
                Delete
              </Button>
            </Link>
          </Space>
        </Col>
      </Row>
      <Divider />
      <Descriptions bordered column={1}>
        <Descriptions.Item label="Is TNE?">
          {data.isTNE ? (
            <Tag icon={<CheckCircleOutlined />} color="success">
              Yes
            </Tag>
          ) : (
            <Tag icon={<CloseCircleOutlined />} color="error">
              No
            </Tag>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Name">{data.name}</Descriptions.Item>
        <Descriptions.Item label="Organization">
          {data.organization ? (
            <Link to={`/organizations/read/${data.organization.id}`}>
              <Button icon={<LinkOutlined />} type="dashed">
                {data.organization.name}
              </Button>
            </Link>
          ) : (
            'Not attached to any organization'
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Institution IDs">
          {data.institutionIds.map((id) => (
            <Tag key={id}>{id}</Tag>
          ))}
        </Descriptions.Item>
        <Descriptions.Item label="Comment">{data.comment}</Descriptions.Item>
        <Descriptions.Item label="Start date">
          {dayjs(data.startDate).format('YYYY-MM-DD')}
        </Descriptions.Item>
        <Descriptions.Item label="Stop date">
          {dayjs(data.stopDate).format('YYYY-MM-DD')}
        </Descriptions.Item>
        <Descriptions.Item label="# Licenses">
          {data.nbLicenses}
        </Descriptions.Item>
        <Descriptions.Item label="Code projet ressource">
          {data.codeProjetRessource}
        </Descriptions.Item>
      </Descriptions>
    </>
  );

Read.propTypes = {
  data: PropTypes.object,
};

Read.defaultProps = {
  data: undefined,
};

export default Read;
