import { EyeOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row, Table } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import {
  sortBool,
  searchBool,
  renderBool,
  sortText,
  searchText,
  sortDate,
  renderDate,
  sortNumber,
} from 'lib/table';

const columns = [
  {
    title: 'School',
    dataIndex: ['data', 'commentaireCommande', 'nomEtablissement'],
    key: 'name',
    ...sortText('name'),
    ...searchText('name'),
  },
  {
    title: 'Start date',
    dataIndex: ['data', 'debutValidite'],
    key: 'date',
    ...sortDate('date'),
    ...renderDate(),
  },
  {
    title: '# Teachers',
    dataIndex: ['data', 'nbLicenceEnseignant'],
    key: 'nbLicenceEnseignant',
    ...sortText('nbLicenceEnseignant'),
    ...searchText('nbLicenceEnseignant'),
  },
  {
    title: '# Students',
    dataIndex: ['data', 'nbLicenceEleve'],
    key: 'nbLicenceEleve',
    ...sortText('nbLicenceEleve'),
    ...searchText('nbLicenceEleve'),
  },
  {
    title: 'Is GAR done?',
    dataIndex: 'isGARDone',
    key: 'isGARDone',
    width: 160,
    ...sortBool('isGARDone'),
    ...searchBool('isGARDone'),
    ...renderBool(),
  },
  {
    title: 'Is TNE done?',
    dataIndex: 'isTNEDone',
    key: 'isTNEDone',
    width: 160,
    ...sortBool('isTNEDone'),
    ...searchBool('isTNEDone'),
    ...renderBool(),
  },
  {
    title: 'Is done?',
    dataIndex: 'isDone',
    key: 'isDone',
    width: 160,
    ...sortBool('isDone'),
    ...searchBool('isDone'),
    ...renderBool(),
  },
  {
    title: '# Retries',
    dataIndex: 'retries',
    key: 'retries',
    width: 160,
    ...sortNumber('retries'),
  },
  {
    title: '',
    key: 'read',
    width: 100,
    // eslint-disable-next-line react/prop-types
    render: ({ id }) => (
      <Link to={`/partners/tnecanope/read/${id}`}>
        <Button icon={<EyeOutlined />}>View</Button>
      </Link>
    ),
  },
];

const List = ({ data }) => (
  <>
    <Row gutter={16}>
      <Col flex="auto">
        <h1>List all TNE Canopé orders</h1>
      </Col>
    </Row>
    <Divider />
    <Table columns={columns} dataSource={data} loading={!data} />
  </>
);

List.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

List.defaultProps = {
  data: undefined,
};

export default List;
