import { LinkOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Row } from 'antd';
import React from 'react';

import './Tools.scss';

const tools = [
  {
    name: 'Crisp',
    link: 'https://app.crisp.chat/website/fa796783-a776-4922-9997-92d6efe01bbe/inbox/',
    description: 'Customer support',
    logo: '/images/crisp.png',
  },
  {
    name: 'Notion',
    link: 'https://www.notion.so/wooclap/Wooflash-5a1a605db96f425a8a5cb872cd893203',
    logo: '/images/notion.png',
    description: 'Knowledge base',
  },
  {
    name: 'Trello',
    link: 'https://trello.com/b/uX6AzWqD/wooflash',
    logo: '/images/trello.png',
    description: 'Task management',
  },
  {
    name: 'Grafana',
    link: 'http://grafana.wooflash.com',
    logo: '/images/grafana.png',
    description: 'Business Intelligence',
  },
  {
    name: 'Flashboard',
    link: '172.31.39.236:3838/Flashboard/',
    logo: '/images/grafana.png',
    description: 'Flashboard',
  },
  {
    name: 'Google Analytics',
    link: 'https://analytics.google.com/analytics/web/#/report-home/a136076646w196425106p191387500',
    logo: '/images/analytics.png',
    description: 'Web Analytics',
  },
  {
    name: 'Hotjar',
    link: 'https://insights.hotjar.com/sites/1328171/dashboard',
    logo: '/images/hotjar.png',
    description: 'Visual feedback',
  },
  {
    name: 'Sentry',
    link: 'https://sentry.io/organizations/wooclap/issues/?project=4186683',
    logo: '/images/sentry.png',
    description: 'Error log',
  },
  {
    name: 'Google Tag Manager',
    link: 'https://tagmanager.google.com/#/container/accounts/4701419674/containers/11297401/workspaces/21',
    logo: '/images/tagmanager.png',
    description: 'Tag manager',
  },
];

const Tools = () => (
  <>
    <h1>Tools</h1>
    <Divider />
    <Row gutter={[16, 16]}>
      {tools.map((tool) => (
        <Col span={4}>
          <Card
            actions={[
              <Button href={tool.link} icon={<LinkOutlined />} target="blank">
                Open {tool.name}
              </Button>,
            ]}
            cover={<img alt="Logo" className="Tools-image" src={tool.logo} />}
          >
            <Card.Meta title={tool.name} description={tool.description} />
          </Card>
        </Col>
      ))}
    </Row>
  </>
);

export default Tools;
