import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useMutationMoodleDelete, useQueryMoodle } from 'gql/moodle';

import Delete from './Delete';

const DeleteContainer = () => {
  const history = useHistory();
  const { id } = useParams();
  const { data } = useQueryMoodle({
    variables: {
      moodleId: id,
    },
  });
  const mutationMoodleDelete = useMutationMoodleDelete();
  const handleDelete = useCallback(async () => {
    try {
      await mutationMoodleDelete({
        moodleId: data.moodle.id,
      });
      message.success('The Moodle service has been deleted.');
      history.push('/lms/moodle');
    } catch (err) {
      message.error('An error occurred while deleting the Moodle service.');
    }
  }, [data, history, mutationMoodleDelete]);
  return <Delete data={data?.moodle} handleDelete={handleDelete} />;
};

export default DeleteContainer;
