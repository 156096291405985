import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  useMutationTranslationDelete,
  useQueryTranslation,
} from 'gql/translation';

import Delete from './Delete';

const DeleteContainer = () => {
  const history = useHistory();
  const { id } = useParams();
  const { data } = useQueryTranslation({
    variables: {
      translationId: id,
    },
  });
  const mutationTranslationDelete = useMutationTranslationDelete();
  const handleDelete = useCallback(async () => {
    try {
      await mutationTranslationDelete({
        translationId: data.translation.id,
      });
      message.success('The translation has been deleted.');
      history.push('/translations');
    } catch (err) {
      message.error('An error occurred while deleting the translation.');
    }
  }, [data, history, mutationTranslationDelete]);
  return <Delete data={data?.translation} handleDelete={handleDelete} />;
};

export default DeleteContainer;
