import { message } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Context from 'lib/context';

import Logout from './Logout';

const LogoutContainer = () => {
  const { handleLogOut } = useContext(Context);
  const history = useHistory();
  useEffect(() => {
    handleLogOut();
    message.success('You are logged out!');
    history.push('/');
  }, [handleLogOut, history]);
  return <Logout />;
};

export default LogoutContainer;
