import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import Upsert from '../Upsert';

const Create = ({ handleCreate }) => (
  <>
    <Row gutter={16}>
      <Col>
        <Link to="/auth/cas">
          <Button icon={<ArrowLeftOutlined />}>Back</Button>
        </Link>
      </Col>
      <Col flex="auto">
        <h1>Create a new CAS strategy</h1>
      </Col>
    </Row>
    <Divider />
    <Upsert handleSubmit={handleCreate} />
  </>
);

Create.propTypes = {
  handleCreate: PropTypes.func.isRequired,
};

export default Create;
