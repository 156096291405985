import { Col, Divider, Row, Table } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import { sortText, searchText, searchTextChoices } from 'lib/table';

const columns = [
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    ...sortText('type'),
    ...searchTextChoices('type', ['cas', 'edugain', 'saml']),
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    ...sortText('name'),
    ...searchText('name'),
  },
  {
    title: 'Specifier',
    dataIndex: 'specifier',
    key: 'specifier',
    ...sortText('specifier'),
    ...searchText('specifier'),
  },
];

const Active = ({ data }) => (
  <>
    <Row gutter={16}>
      <Col flex="auto">
        <h1>Listing all active SSO strategies</h1>
      </Col>
    </Row>
    <Divider />
    <Table columns={columns} dataSource={data} loading={!data} />
  </>
);

Active.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

Active.defaultProps = {
  data: undefined,
};

export default Active;
