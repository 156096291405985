import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useQueryCoupon, useMutationCouponDelete } from 'gql/coupon';

import Delete from './Delete';

const DeleteContainer = () => {
  const history = useHistory();
  const { id } = useParams();
  const { data } = useQueryCoupon({
    variables: {
      couponId: id,
    },
  });
  const mutationCouponDelete = useMutationCouponDelete();
  const handleDelete = useCallback(async () => {
    try {
      await mutationCouponDelete({
        couponId: data.coupon.id,
      });
      message.success('The coupon has been deleted.');
      history.push('/coupons');
    } catch (err) {
      message.error('An error occurred while deleting the coupon.');
    }
  }, [data, history, mutationCouponDelete]);
  return <Delete data={data?.coupon} handleDelete={handleDelete} />;
};

export default DeleteContainer;
