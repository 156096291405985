import React from 'react';

import { useQueryAccounts } from 'gql/account';

import List from './List';

const ListContainer = () => {
  const { data } = useQueryAccounts();
  return <List data={data?.accounts} />;
};

export default ListContainer;
