import React from 'react';

import { useQuerySAMLs } from 'gql/saml';

import List from './List';

const ListContainer = () => {
  const { data } = useQuerySAMLs();
  return <List data={data?.SAMLs} />;
};

export default ListContainer;
