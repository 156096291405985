import { gql, useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';

const QUERY_COUPONS = gql`
  {
    coupons {
      id
      planId
      organizationId
      name
      accessCode
      plan {
        id
        name
      }
      organization {
        id
        name
      }
    }
  }
`;

export function useQueryCoupons() {
  const { loading, data, ...props } = useQuery(QUERY_COUPONS);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

const QUERY_COUPON = gql`
  query coupon($couponId: ID!) {
    coupon(couponId: $couponId) {
      id
      planId
      organizationId
      name
      accessCode
      plan {
        id
        name
      }
      organization {
        id
        name
      }
    }
  }
`;

export function useQueryCoupon(options) {
  const { loading, data, ...props } = useQuery(QUERY_COUPON, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const COUPON_CREATE = gql`
  mutation CouponCreate(
    $planId: ID
    $organizationId: ID
    $name: String!
    $accessCode: String!
  ) {
    couponCreate(
      planId: $planId
      organizationId: $organizationId
      name: $name
      accessCode: $accessCode
    ) {
      id
    }
  }
`;

export function useMutationCouponCreate() {
  const [mutation] = useMutation(COUPON_CREATE);
  return useCallback((variables) => mutation({ variables }), [mutation]);
}

export const COUPON_UPDATE = gql`
  mutation CouponUpdate(
    $couponId: ID!
    $planId: ID
    $organizationId: ID
    $name: String
    $accessCode: String
  ) {
    couponUpdate(
      couponId: $couponId
      planId: $planId
      organizationId: $organizationId
      name: $name
      accessCode: $accessCode
    ) {
      id
    }
  }
`;

export function useMutationCouponUpdate() {
  const [mutation] = useMutation(COUPON_UPDATE);
  return useCallback((variables) => mutation({ variables }), [mutation]);
}

export const COUPON_DELETE = gql`
  mutation couponDelete($couponId: ID!) {
    couponDelete(couponId: $couponId)
  }
`;

export function useMutationCouponDelete() {
  const [mutation] = useMutation(COUPON_DELETE);
  return useCallback((variables) => mutation({ variables }), [mutation]);
}
