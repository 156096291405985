import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

export const TAGS_CREATE = gql`
  mutation TagsCreate($tags: [String]!) {
    tagsCreate(tags: $tags) {
      id
      title
    }
  }
`;

export function useMutationTagsCreate() {
  const [mutation] = useMutation(TAGS_CREATE);
  return useCallback(
    (variables) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}
