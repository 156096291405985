import React from 'react';

import { useQueryPlans } from 'gql/plan';

import List from './List';

const ListContainer = () => {
  const { data } = useQueryPlans();
  return <List data={data?.plans} />;
};

export default ListContainer;
