import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useQueryPublicCourse, useMutationCourseUpdate } from 'gql/course';
import { useMutationTagsCreate } from 'gql/tag';

import Update from './Update';

const UpdateContainer = () => {
  const history = useHistory();
  const { id } = useParams();
  const { data } = useQueryPublicCourse({
    variables: {
      courseId: id,
    },
  });
  const mutationCourseUpdate = useMutationCourseUpdate();
  const mutationTagsCreate = useMutationTagsCreate();
  const handleUpdate = useCallback(
    async (values) => {
      try {
        const {
          data: { tagsCreate },
        } = await mutationTagsCreate({ tags: values.tags });
        const courseTagIds = tagsCreate.map((tag) => tag.id);
        await mutationCourseUpdate({
          ...values,
          courseId: data.publicCourse.id,
          tagIds: courseTagIds,
        });
        message.success('The course has been updated.');
        history.push(`/public-courses/read/${data.publicCourse.id}`);
      } catch (err) {
        message.error('An error occurred while updating the course.');
      }
    },
    [data, history, mutationCourseUpdate, mutationTagsCreate],
  );
  return <Update data={data?.publicCourse} handleUpdate={handleUpdate} />;
};

export default UpdateContainer;
