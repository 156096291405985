import { SendOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Switch,
} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

const Upsert = ({ data, handleSubmit, institutions, organizations }) => (
  <Form
    initialValues={{
      organizationId: data.organizationId || null,
      isTNE: data.isTNE || false,
      name: data.name || '',
      institutionId: data.institutionIds ? data.institutionIds[0] : '',
      comment: data.comment || '',
      startDate: data.startDate ? moment(data.startDate) : null,
      stopDate: data.stopDate ? moment(data.stopDate) : null,
      licenseType: data.licenseType || 'ETABL',
      nbLicenses: data.nbLicenses || 0,
      codeProjetRessource: data.codeProjetRessource || '',
    }}
    layout="vertical"
    onFinish={handleSubmit}
  >
    <Form.Item
      label="Turn this switch on if this subscription is for TNE Canopé."
      name="isTNE"
      valuePropName="checked"
    >
      <Switch />
    </Form.Item>
    <Form.Item
      label="Name of the GAR subscription (e.g. Wooclap)."
      name="name"
      rules={[{ required: true }]}
    >
      <Input placeholder="Wooclap" />
    </Form.Item>
    <Form.Item
      label="Identifier of the institution as seen in the GAR list of institutions."
      name="institutionId"
      rules={[{ required: true }]}
    >
      <Select
        allowClear
        showSearch
        filterOption={(input, option) =>
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        options={[
          {
            label: 'Not attached to any institution',
            value: null,
          },
          ...institutions.map((institution) => ({
            label: `${institution.appellation_officielle} (${institution.uai})`,
            value: institution.uai,
          })),
        ]}
      />
    </Form.Item>
    <Form.Item label="Comment" name="comment">
      <Input placeholder="..." />
    </Form.Item>
    <Form.Item
      label="Start date of the subscription"
      name="startDate"
      rules={[{ required: true }]}
    >
      <DatePicker />
    </Form.Item>
    <Form.Item
      label="Stop date of the subscription"
      name="stopDate"
      rules={[{ required: true }]}
    >
      <DatePicker />
    </Form.Item>
    <Form.Item
      label="License type"
      name="licenseType"
      rules={[{ required: true }]}
    >
      <Select>
        <Select.Option value="ETABL">
          Établissement (institution wide) = unlimited licenses
        </Select.Option>
        <Select.Option value="INDIV">
          Individuelle = limited number of licenses
        </Select.Option>
      </Select>
    </Form.Item>
    <Form.Item
      label="Number of licenses granted to the institution (0 = Unlimited)"
      name="nbLicenses"
      rules={[{ required: true }]}
      extra={
        <Alert
          banner
          message="Set to 0 when opting for an institution wide license. Provide a number when opting for individual licenses"
          type="warning"
        />
      }
    >
      <InputNumber placeholder="42" />
    </Form.Item>
    <Form.Item
      label="Code projet ressource"
      name="codeProjetRessource"
      rules={[{ required: false }]}
    >
      <Input placeholder="Enter the 'code projet ressource'" />
    </Form.Item>
    <Form.Item
      label="Organization associated with the GAR subscription."
      name="organizationId"
    >
      <Select
        allowClear
        showSearch
        filterOption={(input, option) =>
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        options={[
          {
            label: 'Not attached to any organization',
            value: null,
          },
          ...organizations.map((organization) => ({
            label: organization.name,
            value: organization.id,
          })),
        ]}
      />
    </Form.Item>
    <Button htmlType="submit" icon={<SendOutlined />} type="primary">
      Submit
    </Button>
  </Form>
);

Upsert.propTypes = {
  data: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  institutions: PropTypes.arrayOf(PropTypes.object),
  organizations: PropTypes.arrayOf(PropTypes.object),
};

Upsert.defaultProps = {
  data: {},
  institutions: [],
  organizations: [],
};

export default Upsert;
