import { gql, useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';

const QUERY_CASS = gql`
  {
    CASs {
      id
      organizationId
      name
      specifier
      domains
      isPublic
      organization {
        id
        name
      }
    }
  }
`;

export function useQueryCASs() {
  const { loading, data, ...props } = useQuery(QUERY_CASS);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

const QUERY_CAS = gql`
  query CAS($casId: ID!) {
    CAS(casId: $casId) {
      id
      organizationId
      name
      specifier
      domains
      adminEmail
      profile
      configuration
      isPublic
      organization {
        id
        name
      }
    }
  }
`;

export function useQueryCAS(options) {
  const { loading, data, ...props } = useQuery(QUERY_CAS, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const CAS_CREATE = gql`
  mutation casCreate(
    $organizationId: ID
    $name: String!
    $specifier: String!
    $domains: [String!]!
    $adminEmail: String
    $profile: JSON!
    $configuration: JSON!
    $isPublic: Boolean
  ) {
    casCreate(
      organizationId: $organizationId
      name: $name
      specifier: $specifier
      domains: $domains
      adminEmail: $adminEmail
      profile: $profile
      configuration: $configuration
      isPublic: $isPublic
    ) {
      id
    }
  }
`;

export function useMutationCASCreate() {
  const [mutation] = useMutation(CAS_CREATE);
  return useCallback((variables) => mutation({ variables }), [mutation]);
}

export const CAS_UPDATE = gql`
  mutation casUpdate(
    $casId: ID!
    $organizationId: ID
    $name: String
    $specifier: String
    $domains: [String!]
    $adminEmail: String
    $profile: JSON
    $configuration: JSON
    $isPublic: Boolean
  ) {
    casUpdate(
      casId: $casId
      organizationId: $organizationId
      name: $name
      specifier: $specifier
      domains: $domains
      adminEmail: $adminEmail
      profile: $profile
      configuration: $configuration
      isPublic: $isPublic
    ) {
      id
    }
  }
`;

export function useMutationCASUpdate() {
  const [mutation] = useMutation(CAS_UPDATE);
  return useCallback((variables) => mutation({ variables }), [mutation]);
}

export const CAS_DELETE = gql`
  mutation casDelete($casId: ID!) {
    casDelete(casId: $casId)
  }
`;

export function useMutationCASDelete() {
  const [mutation] = useMutation(CAS_DELETE);
  return useCallback((variables) => mutation({ variables }), [mutation]);
}
