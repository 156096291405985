import { PlusOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select, Switch, Upload } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import storeJS from 'store';

const Upsert = ({ data, form, handleSubmit, organizations, plans }) => (
  <Form
    form={form}
    initialValues={{
      organizationId: data.organizationId || null,
      planId: data.planId || null,
      tags: data.tags || [],
      wantsMarketing: data.wantsMarketing || false,
      firstName: data.firstName || '',
      lastName: data.lastName || '',
      picture: data.picture || '',
      language: data.language || '',
      sector: data.sector || '',
      role: data.role || '',
      institution: data.institution || '',
      branch: data.branch || '',
      addressLine1: data.addressLine1 || '',
      addressLine2: data.addressLine2 || '',
      city: data.city || '',
      zip: data.zip || '',
      state: data.state || '',
      country: data.country || '',
      company: data.company || '',
      vat: data.vat || '',
      emailNotifications: {
        comments: true,
        invitations: true,
        questionPropose: true,
        expertFeedback: true,
        courseProgress: true,
        ...(data.emailNotifications || {}),
      },
      pushNotifications: {
        comments: true,
        invitations: true,
        questionPropose: true,
        expertFeedback: true,
        ...(data.pushNotifications || {}),
      },
      pushToken: data.pushToken || '',
      job: data.job || '',
      biography: data.biography || '',
      twitter: data.twitter || '',
      linkedin: data.linkedin || '',
      isAmbassador: data.isAmbassador || false,
      metadata: {
        accountVerification: null,
        notificationLastRead: null,
        ...(data.metadata || {}),
      },
    }}
    layout="vertical"
    onFinish={handleSubmit}
  >
    <Form.Item label="First name" name="firstName">
      <Input />
    </Form.Item>
    <Form.Item label="Last name" name="lastName">
      <Input />
    </Form.Item>
    <Form.Item label="Tags" name="tags">
      <Select mode="tags" />
    </Form.Item>
    <Form.Item
      label="Wants marketing?"
      name="wantsMarketing"
      valuePropName="checked"
    >
      <Switch />
    </Form.Item>
    <Form.Item shouldUpdate noStyle>
      {() => (
        <Form.Item
          label="Picture"
          name="picture"
          getValueFromEvent={(e) => e?.file?.response?.data.url || ''}
        >
          <Upload
            name="file"
            accept="image/*"
            action={`${process.env.REACT_APP_API_URL}/api/uploads/`}
            headers={{
              Authorization: `Bearer ${storeJS.get('token')}`,
            }}
            listType="picture-card"
            showUploadList={false}
          >
            {form.getFieldValue('picture') ? (
              <img
                alt=""
                src={form.getFieldValue('picture')}
                style={{ maxWidth: '100%', maxHeight: '100%' }}
              />
            ) : (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            )}
          </Upload>
        </Form.Item>
      )}
    </Form.Item>
    <Form.Item label="Language" name="language" rules={[{ required: true }]}>
      <Select>
        <Select.Option value="en">English</Select.Option>
        <Select.Option value="fr">French</Select.Option>
        <Select.Option value="nl">Dutch</Select.Option>
      </Select>
    </Form.Item>
    <Form.Item label="Plan associated with the account." name="planId">
      <Select
        allowClear
        showSearch
        filterOption={(input, option) =>
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        options={[
          {
            label: 'Not attached to any plan',
            value: null,
          },
          ...plans.map((plan) => ({
            label: plan.name,
            value: plan.id,
          })),
        ]}
      />
    </Form.Item>
    <Form.Item
      label="Organization associated with the account."
      name="organizationId"
    >
      <Select
        allowClear
        showSearch
        filterOption={(input, option) =>
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        options={[
          {
            label: 'Not attached to any organization',
            value: null,
          },
          ...organizations.map((organization) => ({
            label: organization.name,
            value: organization.id,
          })),
        ]}
      />
    </Form.Item>
    <Form.Item label="Sector" name="sector">
      <Input />
    </Form.Item>
    <Form.Item label="Role" name="role">
      <Input />
    </Form.Item>
    <Form.Item label="Institution" name="institution">
      <Input />
    </Form.Item>
    <Form.Item label="Branch" name="branch">
      <Input />
    </Form.Item>
    <Form.Item label="Address line 1" name="addressLine1">
      <Input />
    </Form.Item>
    <Form.Item label="Address line 2" name="addressLine2">
      <Input />
    </Form.Item>
    <Form.Item label="City" name="city">
      <Input />
    </Form.Item>
    <Form.Item label="Zip" name="zip">
      <Input />
    </Form.Item>
    <Form.Item label="State" name="state">
      <Input />
    </Form.Item>
    <Form.Item label="Country" name="country">
      <Input />
    </Form.Item>
    <Form.Item label="Company" name="company">
      <Input />
    </Form.Item>
    <Form.Item label="VAT" name="vat">
      <Input />
    </Form.Item>
    <Form.Item
      label="Email notifications for comments"
      name={['emailNotifications', 'comments']}
      valuePropName="checked"
    >
      <Switch />
    </Form.Item>
    <Form.Item
      label="Email notifications for invitations"
      name={['emailNotifications', 'invitations']}
      valuePropName="checked"
    >
      <Switch />
    </Form.Item>
    <Form.Item
      label="Email notifications for question proposal"
      name={['emailNotifications', 'questionPropose']}
      valuePropName="checked"
    >
      <Switch />
    </Form.Item>
    <Form.Item
      label="Email notifications for expert feedback"
      name={['emailNotifications', 'expertFeedback']}
      valuePropName="checked"
    >
      <Switch />
    </Form.Item>
    <Form.Item
      label="Email notifications for course progress"
      name={['emailNotifications', 'courseProgress']}
      valuePropName="checked"
    >
      <Switch />
    </Form.Item>
    <Form.Item
      label="Push notifications for comments"
      name={['pushNotifications', 'comments']}
      valuePropName="checked"
    >
      <Switch />
    </Form.Item>
    <Form.Item
      label="Push notifications for invitations"
      name={['pushNotifications', 'invitations']}
      valuePropName="checked"
    >
      <Switch />
    </Form.Item>
    <Form.Item
      label="Push notifications for question proposal"
      name={['pushNotifications', 'questionPropose']}
      valuePropName="checked"
    >
      <Switch />
    </Form.Item>
    <Form.Item
      label="Push notifications for expert feedback"
      name={['pushNotifications', 'expertFeedback']}
      valuePropName="checked"
    >
      <Switch />
    </Form.Item>
    <Form.Item label="Push token" name="pushToken">
      <Input />
    </Form.Item>
    <Form.Item label="Job" name="job">
      <Input />
    </Form.Item>
    <Form.Item label="Biography" name="biography">
      <Input />
    </Form.Item>
    <Form.Item label="Twitter" name="twitter">
      <Input />
    </Form.Item>
    <Form.Item label="Linkedin" name="linkedin">
      <Input />
    </Form.Item>
    <Form.Item
      label="is ambassador"
      name="isAmbassador"
      valuePropName="checked"
    >
      <Switch />
    </Form.Item>
    <Button htmlType="submit" icon={<SendOutlined />} type="primary">
      Submit
    </Button>
  </Form>
);

Upsert.propTypes = {
  data: PropTypes.object,
  form: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  organizations: PropTypes.arrayOf(PropTypes.object),
  plans: PropTypes.arrayOf(PropTypes.object),
};

Upsert.defaultProps = {
  data: {},
  organizations: [],
  plans: [],
};

export default Upsert;
