import {
  ArrowLeftOutlined,
  BugOutlined,
  CheckCircleOutlined,
  CheckSquareOutlined,
  CloseCircleOutlined,
  EditOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Descriptions,
  Divider,
  Row,
  Space,
  Spin,
  Tag,
} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const Read = ({ data }) =>
  !data ? (
    <Spin />
  ) : (
    <>
      <Row gutter={16}>
        <Col>
          <Link to="/auth/edugain">
            <Button icon={<ArrowLeftOutlined />}>Back</Button>
          </Link>
        </Col>
        <Col flex="auto">
          <h1>{`View an eduGAIN strategy - ${data.name}`}</h1>
        </Col>
        <Col>
          <Space>
            <Link to={`/auth/edugain/update/${data.id}`}>
              <Button icon={<EditOutlined />} type="primary">
                Update
              </Button>
            </Link>
            <Button
              href={`${process.env.REACT_APP_API_URL}/api/auth/edugain/${data.specifier}/login?debug=true`}
              icon={<BugOutlined />}
            >
              Debug
            </Button>
            <Button
              href={`${process.env.REACT_APP_API_URL}/api/auth/edugain/${data.specifier}/login`}
              icon={<CheckSquareOutlined />}
              type="primary"
            >
              Test
            </Button>
          </Space>
        </Col>
      </Row>
      <Divider />
      <Descriptions bordered column={1}>
        <Descriptions.Item label="Name">{data.name}</Descriptions.Item>
        <Descriptions.Item label="Specifier">
          {data.specifier}
        </Descriptions.Item>
        <Descriptions.Item label="Domains">
          {data.domains.map((domain, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Tag key={index}>{domain}</Tag>
          ))}
        </Descriptions.Item>
        <Descriptions.Item label="Organization">
          {data.organization ? (
            <Link to={`/organizations/read/${data.organization.id}`}>
              <Button icon={<LinkOutlined />} type="dashed">
                {data.organization.name}
              </Button>
            </Link>
          ) : (
            'Not attached to any organization'
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Admin email">
          {data.adminEmail}
        </Descriptions.Item>
        <Descriptions.Item label="Metadata">
          <pre>
            <code>{data.metadata}</code>
          </pre>
        </Descriptions.Item>
        <Descriptions.Item label="Is public?">
          {data.isPublic ? (
            <Tag icon={<CheckCircleOutlined />} color="success">
              Yes
            </Tag>
          ) : (
            <Tag icon={<CloseCircleOutlined />} color="error">
              No
            </Tag>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Overrides">
          <pre>
            <code>{JSON.stringify(data.overrides, null, 2)}</code>
          </pre>
        </Descriptions.Item>
      </Descriptions>
    </>
  );

Read.propTypes = {
  data: PropTypes.object,
};

Read.defaultProps = {
  data: undefined,
};

export default Read;
