import React from 'react';

import { useQueryTranslations } from 'gql/translation';

import List from './List';

const ListContainer = () => {
  const { data } = useQueryTranslations();
  return <List data={data?.translations} />;
};

export default ListContainer;
