import {
  ArrowLeftOutlined,
  DeleteOutlined,
  EditOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  Descriptions,
  Divider,
  Row,
  Space,
  Spin,
  Tag,
} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const Read = ({ data }) =>
  !data ? (
    <Spin />
  ) : (
    <>
      <Row gutter={16}>
        <Col>
          <Link to="/lms/lti/v1p1">
            <Button icon={<ArrowLeftOutlined />}>Back</Button>
          </Link>
        </Col>
        <Col flex="auto">
          <h1>{`View a LTI 1.1 service - ${data.name}`}</h1>
        </Col>
        <Col>
          <Space>
            <Link to={`/lms/lti/v1p1/update/${data.id}`}>
              <Button icon={<EditOutlined />} type="primary">
                Update
              </Button>
            </Link>
            <Link to={`/lms/lti/v1p1/delete/${data.id}`}>
              <Button icon={<DeleteOutlined />} type="danger">
                Delete
              </Button>
            </Link>
          </Space>
        </Col>
      </Row>
      <Divider />
      <Descriptions bordered column={1}>
        <Descriptions.Item label="Name">{data.name}</Descriptions.Item>
        <Descriptions.Item label="Specifier">
          {data.specifier}
        </Descriptions.Item>
        <Descriptions.Item label="Domains">
          {data.domains.map((domain, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Tag key={index}>{domain}</Tag>
          ))}
        </Descriptions.Item>
        <Descriptions.Item label="Organization">
          {data.organization ? (
            <Link to={`/organizations/read/${data.organization.id}`}>
              <Button icon={<LinkOutlined />} type="dashed">
                {data.organization.name}
              </Button>
            </Link>
          ) : (
            'Not attached to any organization'
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Admin email">
          {data.adminEmail}
        </Descriptions.Item>
      </Descriptions>
      <Divider />
      <p>
        <Alert
          message="Information to provide to the client"
          description="This is the information the client will need in order to configure his LMS."
          type="info"
          showIcon
        />
      </p>
      <Descriptions bordered column={1}>
        <Descriptions.Item label="Tool URL">
          https://api.wooflash.com/rest/lti/v1p1/launch/
        </Descriptions.Item>
        <Descriptions.Item label="LTI version">LTI 1.1</Descriptions.Item>
        <Descriptions.Item label="Access key ID / Consumer key">
          {data.accessKeyId}
        </Descriptions.Item>
        <Descriptions.Item label="Secret access key / Shared secret">
          {data.secretAccessKey}
        </Descriptions.Item>
      </Descriptions>
    </>
  );

Read.propTypes = {
  data: PropTypes.object,
};

Read.defaultProps = {
  data: undefined,
};

export default Read;
