import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useMutationTranslationCreate } from 'gql/translation';

import Create from './Create';

const CreateContainer = () => {
  const history = useHistory();
  const mutationTranslationCreate = useMutationTranslationCreate();
  const handleCreate = useCallback(
    async (values) => {
      try {
        const { data } = await mutationTranslationCreate(values);
        message.success('The translation has been created.');
        history.push(`/translations/read/${data.translationCreate.id}`);
      } catch (err) {
        message.error('An error occurred while creating the translation.');
      }
    },
    [history, mutationTranslationCreate],
  );
  return <Create handleCreate={handleCreate} />;
};

export default CreateContainer;
