import {
  ArrowLeftOutlined,
  DeleteOutlined,
  EditOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import {
  Badge,
  Button,
  Col,
  Descriptions,
  Divider,
  Row,
  Space,
  Spin,
} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const Read = ({ data }) =>
  !data ? (
    <Spin />
  ) : (
    <>
      <Row gutter={16}>
        <Col>
          <Link to="/plans">
            <Button icon={<ArrowLeftOutlined />}>Back</Button>
          </Link>
        </Col>
        <Col flex="auto">
          <h1>{`View a plan - ${data.name}`}</h1>
        </Col>
        <Col>
          <Space>
            <Link to={`/plans/update/${data.id}`}>
              <Button icon={<EditOutlined />} type="primary">
                Update
              </Button>
            </Link>
            <Link to={`/plans/delete/${data.id}`}>
              <Button icon={<DeleteOutlined />} type="danger">
                Delete
              </Button>
            </Link>
          </Space>
        </Col>
      </Row>
      <Divider />
      <Descriptions bordered column={1}>
        <Descriptions.Item label="Name">{data.name}</Descriptions.Item>
        <Descriptions.Item label="Organizations">
          <p>
            <Badge count={data.organizations.length} overflowCount={999999} />
          </p>
          {data.organizations.map((organization) => (
            <Link
              key={organization.id}
              to={`/organizations/read/${organization.id}`}
            >
              <Button icon={<LinkOutlined />} type="dashed">
                {organization.name}
              </Button>
            </Link>
          ))}
        </Descriptions.Item>
        <Descriptions.Item label="Accounts">
          <p>
            <Badge count={data.accounts.length} overflowCount={999999} />
          </p>
          {data.accounts.map((account) => (
            <Link key={account.id} to={`/accounts/read/${account.id}`}>
              <Button icon={<LinkOutlined />} type="dashed">
                {`${account.firstName} ${account.lastName}`}
              </Button>
            </Link>
          ))}
        </Descriptions.Item>
      </Descriptions>
    </>
  );

Read.propTypes = {
  data: PropTypes.object,
};

Read.defaultProps = {
  data: undefined,
};

export default Read;
