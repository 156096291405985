import { Form } from 'antd';
import React from 'react';

import Upsert from './Upsert';

const UpsertContainer = (props) => {
  const [form] = Form.useForm();
  return <Upsert form={form} {...props} />;
};

export default UpsertContainer;
