import { ArrowLeftOutlined, SendOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Divider, Form, Input, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const Verification = ({ handleVerificationResend }) => (
  <>
    <Row gutter={16}>
      <Col>
        <Link to="/auth/local">
          <Button icon={<ArrowLeftOutlined />}>Back</Button>
        </Link>
      </Col>
      <Col flex="auto">
        <h1>Resend a verification email</h1>
      </Col>
    </Row>
    <Divider />
    <p>
      <Alert
        message="Information"
        description="Please ensure the user is aware that he is about to receive a verification email. Once this email is sent, any previous verification code will be invalidated."
        type="info"
        showIcon
      />
    </p>
    <Form
      initialValues={{
        email: '',
      }}
      layout="vertical"
      onFinish={handleVerificationResend}
    >
      <Form.Item
        label="Email address"
        name="email"
        rules={[{ required: true }]}
      >
        <Input placeholder="james@bond.uk" />
      </Form.Item>
      <Button htmlType="submit" icon={<SendOutlined />} type="primary">
        Submit
      </Button>
    </Form>
  </>
);

Verification.propTypes = {
  handleVerificationResend: PropTypes.func.isRequired,
};

export default Verification;
