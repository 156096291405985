/* eslint-disable react/prop-types */
import * as dayjs from 'dayjs';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Button, Input, Space, Tag } from 'antd';
import React from 'react';

export const sortText = (dataIndex, multiple = 1) => ({
  sorter: {
    compare: (a, b) =>
      a[dataIndex].localeCompare(b[dataIndex], 'en', {
        sensitivity: 'base',
        ignorePunctuation: true,
        numeric: true,
      }),
    multiple,
  },
});

export const searchText = (dataIndex) => ({
  filterDropdown: ({
    clearFilters,
    confirm,
    selectedKeys,
    setSelectedKeys,
  }) => (
    <div
      style={{
        padding: 8,
      }}
    >
      <Input
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={confirm}
        style={{
          width: 188,
          marginBottom: 8,
          display: 'block',
        }}
      />
      <Space>
        <Button
          icon={<SearchOutlined />}
          onClick={confirm}
          size="small"
          style={{
            width: 90,
          }}
          type="primary"
        >
          Search
        </Button>
        <Button
          onClick={clearFilters}
          size="small"
          style={{
            width: 90,
          }}
        >
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined
      style={{
        color: filtered ? '#1890ff' : undefined,
      }}
    />
  ),
  onFilter: (value, record) =>
    record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : '',
});

export const searchTextChoices = (dataIndex, choices) => ({
  filters: choices.map((choice) => ({
    text: choice,
    value: choice,
  })),
  filterMultiple: false,
  onFilter: (value, record) => record[dataIndex] === value,
});

export const sortNumber = (dataIndex, multiple = 1) => ({
  sorter: {
    compare: (a, b) => a[dataIndex] - b[dataIndex],
    multiple,
  },
});

export const searchArray = (dataIndex) => ({
  filterDropdown: ({
    clearFilters,
    confirm,
    selectedKeys,
    setSelectedKeys,
  }) => (
    <div
      style={{
        padding: 8,
      }}
    >
      <Input
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={confirm}
        style={{
          width: 188,
          marginBottom: 8,
          display: 'block',
        }}
      />
      <Space>
        <Button
          icon={<SearchOutlined />}
          onClick={confirm}
          size="small"
          style={{
            width: 90,
          }}
          type="primary"
        >
          Search
        </Button>
        <Button
          onClick={clearFilters}
          size="small"
          style={{
            width: 90,
          }}
        >
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined
      style={{
        color: filtered ? '#1890ff' : undefined,
      }}
    />
  ),
  onFilter: (value, record) =>
    record[dataIndex]
      ? record[dataIndex].some((v) =>
          v.toString().toLowerCase().includes(value.toLowerCase()),
        )
      : '',
});

export const renderArray = () => ({
  // eslint-disable-next-line react/no-array-index-key
  render: (arr) => arr.map((a, index) => <Tag key={index}>{a}</Tag>),
});

export const renderDate = () => ({
  // eslint-disable-next-line react/no-array-index-key
  render: (d) => dayjs(d).format('YYYY-MM-DD'),
});

export const sortDate = (dataIndex, multiple = 1) => ({
  sorter: {
    compare: (a, b) => new Date(a[dataIndex]) - new Date(b[dataIndex]),
    multiple,
  },
});

export const sortBool = (dataIndex, multiple = 1) => ({
  sorter: {
    compare: (a, b) => (a[dataIndex] ? 1 : 0) - (b[dataIndex] ? 1 : 0),
    multiple,
  },
});

export const searchBool = (dataIndex) => ({
  filters: [
    {
      text: 'Yes',
      value: 'yes',
    },
    {
      text: 'No',
      value: 'no',
    },
  ],
  filterMultiple: false,
  onFilter: (value, record) =>
    (value === 'yes' && record[dataIndex]) ||
    (value === 'no' && !record[dataIndex]),
});

export const renderBool = () => ({
  render: (b) =>
    b ? (
      <Tag icon={<CheckCircleOutlined />} color="success">
        Yes
      </Tag>
    ) : (
      <Tag icon={<CloseCircleOutlined />} color="error">
        No
      </Tag>
    ),
});
