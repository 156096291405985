import { gql, useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';

const QUERY_ACCOUNTS = gql`
  {
    accounts {
      id
      firstName
      lastName
      email
      username
      isVerified
    }
  }
`;

export function useQueryAccounts() {
  const { loading, data, ...props } = useQuery(QUERY_ACCOUNTS);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

const QUERY_ACCOUNT = gql`
  query account($accountId: ID!) {
    account(accountId: $accountId) {
      id
      organizationId
      planId
      isVerified
      tags
      wantsMarketing
      firstName
      lastName
      email
      username
      picture
      language
      sector
      role
      institution
      branch
      addressLine1
      addressLine2
      city
      zip
      state
      country
      company
      vat
      emailNotifications
      pushNotifications
      pushToken
      job
      biography
      twitter
      linkedin
      isAmbassador
      job
      metadata
      plan {
        id
        name
      }
      organization {
        id
        name
      }
      referrals {
        id
        username
        isVerified
      }
      authMethods {
        strategy
      }
    }
  }
`;

export function useQueryAccount(options) {
  const { loading, data, ...props } = useQuery(QUERY_ACCOUNT, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const ACCOUNT_UPDATE = gql`
  mutation AccountUpdate(
    $accountId: ID!
    $planId: ID
    $organizationId: ID
    $tags: [String]
    $wantsMarketing: Boolean
    $firstName: String
    $lastName: String
    $picture: String
    $language: String
    $sector: String
    $role: String
    $institution: String
    $branch: String
    $addressLine1: String
    $addressLine2: String
    $city: String
    $zip: String
    $state: String
    $country: String
    $company: String
    $vat: String
    $emailNotifications: JSON
    $pushNotifications: JSON
    $pushToken: String
    $job: String
    $biography: String
    $twitter: String
    $linkedin: String
    $isAmbassador: Boolean
    $metadata: JSON
  ) {
    accountUpdate(
      accountId: $accountId
      planId: $planId
      organizationId: $organizationId
      tags: $tags
      wantsMarketing: $wantsMarketing
      firstName: $firstName
      lastName: $lastName
      picture: $picture
      language: $language
      sector: $sector
      role: $role
      institution: $institution
      branch: $branch
      addressLine1: $addressLine1
      addressLine2: $addressLine2
      city: $city
      zip: $zip
      state: $state
      country: $country
      company: $company
      vat: $vat
      emailNotifications: $emailNotifications
      pushNotifications: pushNotifications
      pushToken: pushToken
      job: $job
      biography: $biography
      twitter: $twitter
      linkedin: $linkedin
      isAmbassador: $isAmbassador
      metadata: $metadata
    ) {
      id
    }
  }
`;

export function useMutationAccountUpdate() {
  const [mutation] = useMutation(ACCOUNT_UPDATE);
  return useCallback(
    (variables) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}

export const ACCOUNT_MERGE = gql`
  mutation AccountMerge($fromId: ID!, $toId: ID!) {
    accountMerge(fromId: $fromId, toId: $toId) {
      id
    }
  }
`;

export function useMutationAccountMerge() {
  const [mutation] = useMutation(ACCOUNT_MERGE);
  return useCallback(
    (variables) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}
