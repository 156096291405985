import React from 'react';

import { useQueryEduGAINs } from 'gql/edugain';

import List from './List';

const ListContainer = () => {
  const { data } = useQueryEduGAINs();
  return <List data={data?.eduGAINs} />;
};

export default ListContainer;
