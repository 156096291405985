import { gql, useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';

const QUERY_LTIV1P3S = gql`
  {
    ltisv1p3 {
      id
      organizationId
      name
      specifier
      domains
      organization {
        id
        name
      }
    }
  }
`;

export function useQueryLTIv1p3s() {
  const { loading, data, ...props } = useQuery(QUERY_LTIV1P3S);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

const QUERY_LTIV1P3 = gql`
  query ltiv1p3($ltiId: ID!) {
    ltiv1p3(ltiId: $ltiId) {
      id
      organizationId
      name
      specifier
      domains
      adminEmail
      consumerURL
      toolClientID
      authorizationURL
      accessTokenURL
      authorizationConfig
      keyPair
      organization {
        id
        name
      }
    }
  }
`;

export function useQueryLTIv1p3(options) {
  const { loading, data, ...props } = useQuery(QUERY_LTIV1P3, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const LTIV1P3_CREATE = gql`
  mutation LTIv1p3Create(
    $organizationId: ID
    $name: String!
    $specifier: String!
    $domains: [String!]!
    $adminEmail: String
    $consumerURL: String!
    $toolClientID: String!
    $authorizationURL: String!
    $accessTokenURL: String!
    $authorizationConfig: JSON!
  ) {
    ltiv1p3Create(
      organizationId: $organizationId
      name: $name
      specifier: $specifier
      domains: $domains
      adminEmail: $adminEmail
      consumerURL: $consumerURL
      toolClientID: $toolClientID
      authorizationURL: $authorizationURL
      accessTokenURL: $accessTokenURL
      authorizationConfig: $authorizationConfig
    ) {
      id
    }
  }
`;

export function useMutationLTIv1p3Create() {
  const [mutation] = useMutation(LTIV1P3_CREATE);
  return useCallback((variables) => mutation({ variables }), [mutation]);
}

export const LTIV1P3_UPDATE = gql`
  mutation LTIv1p3Update(
    $ltiId: ID!
    $organizationId: ID
    $name: String!
    $specifier: String
    $domains: [String!]
    $adminEmail: String
    $consumerURL: String!
    $toolClientID: String!
    $authorizationURL: String!
    $accessTokenURL: String!
    $authorizationConfig: JSON!
  ) {
    ltiv1p3Update(
      ltiId: $ltiId
      organizationId: $organizationId
      name: $name
      specifier: $specifier
      domains: $domains
      adminEmail: $adminEmail
      consumerURL: $consumerURL
      toolClientID: $toolClientID
      authorizationURL: $authorizationURL
      accessTokenURL: $accessTokenURL
      authorizationConfig: $authorizationConfig
    ) {
      id
    }
  }
`;

export function useMutationLTIv1p3Update() {
  const [mutation] = useMutation(LTIV1P3_UPDATE);
  return useCallback((variables) => mutation({ variables }), [mutation]);
}

export const LTIV1P3_DELETE = gql`
  mutation LTIv1p3Delete($ltiId: ID!) {
    ltiv1p3Delete(ltiId: $ltiId)
  }
`;

export function useMutationLTIv1p3Delete() {
  const [mutation] = useMutation(LTIV1P3_DELETE);
  return useCallback((variables) => mutation({ variables }), [mutation]);
}
