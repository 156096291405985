import { gql, useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';

const QUERY_EDUGAINS = gql`
  {
    eduGAINs {
      id
      organizationId
      name
      specifier
      domains
      isPublic
      organization {
        id
        name
      }
    }
  }
`;

export function useQueryEduGAINs() {
  const { loading, data, ...props } = useQuery(QUERY_EDUGAINS);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

const QUERY_EDUGAIN = gql`
  query eduGAIN($edugainId: ID!) {
    eduGAIN(edugainId: $edugainId) {
      id
      organizationId
      name
      specifier
      domains
      adminEmail
      metadata
      isPublic
      overrides
      organization {
        id
        name
      }
    }
  }
`;

export function useQueryEduGAIN(options) {
  const { loading, data, ...props } = useQuery(QUERY_EDUGAIN, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const EDUGAIN_UPDATE = gql`
  mutation edugainUpdate(
    $edugainId: ID!
    $organizationId: ID
    $adminEmail: String
    $isPublic: Boolean
    $overrides: JSON
  ) {
    edugainUpdate(
      edugainId: $edugainId
      organizationId: $organizationId
      adminEmail: $adminEmail
      isPublic: $isPublic
      overrides: $overrides
    ) {
      id
    }
  }
`;

export function useMutationEduGAINUpdate() {
  const [mutation] = useMutation(EDUGAIN_UPDATE);
  return useCallback((variables) => mutation({ variables }), [mutation]);
}

export const EDUGAIN_UPDATE_ALL = gql`
  mutation edugainUpdateAll {
    edugainUpdateAll
  }
`;

export function useMutationEduGAINUpdateAll() {
  const [mutation] = useMutation(EDUGAIN_UPDATE_ALL);
  return useCallback(() => mutation(), [mutation]);
}
