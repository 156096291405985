import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useMutationPlanCreate } from 'gql/plan';

import Create from './Create';

const CreateContainer = () => {
  const history = useHistory();
  const mutationPlanCreate = useMutationPlanCreate();
  const handleCreate = useCallback(
    async (values) => {
      try {
        const { data } = await mutationPlanCreate(values);
        message.success('The plan has been created.');
        history.push(`/plans/read/${data.planCreate.id}`);
      } catch (err) {
        message.error('An error occurred while creating the plan.');
      }
    },
    [history, mutationPlanCreate],
  );
  return <Create handleCreate={handleCreate} />;
};

export default CreateContainer;
