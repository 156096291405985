import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  useMutationOrganizationDelete,
  useQueryOrganization,
} from 'gql/organization';

import Delete from './Delete';

const DeleteContainer = () => {
  const history = useHistory();
  const { id } = useParams();
  const { data } = useQueryOrganization({
    variables: {
      organizationId: id,
    },
  });
  const mutationOrganizationDelete = useMutationOrganizationDelete();
  const handleDelete = useCallback(async () => {
    try {
      await mutationOrganizationDelete({
        organizationId: data.organization.id,
      });
      message.success('The organization has been deleted.');
      history.push('/organizations');
    } catch (err) {
      message.error('An error occurred while deleting the organization.');
    }
  }, [data, history, mutationOrganizationDelete]);
  return <Delete data={data?.organization} handleDelete={handleDelete} />;
};

export default DeleteContainer;
