import { gql, useQuery } from '@apollo/client';

const QUERY_ME = gql`
  {
    me {
      accessRights
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export function useQueryMe(options) {
  const { loading, data, ...props } = useQuery(QUERY_ME, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}
