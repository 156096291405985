import { useQueryLTIv1p3s } from 'gql/ltiv1p3';

import React from 'react';

import List from './List';

const ListContainer = () => {
  const { data } = useQueryLTIv1p3s();
  return <List data={data?.ltisv1p3} />;
};

export default ListContainer;
