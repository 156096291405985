import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useQueryLTIv1p3, useMutationLTIv1p3Delete } from 'gql/ltiv1p3';

import Delete from './Delete';

const DeleteContainer = () => {
  const history = useHistory();
  const { id } = useParams();
  const { data } = useQueryLTIv1p3({
    variables: {
      ltiId: id,
    },
  });
  const mutationLTIv1p3Delete = useMutationLTIv1p3Delete();
  const handleDelete = useCallback(async () => {
    try {
      await mutationLTIv1p3Delete({
        ltiId: data.ltiv1p3.id,
      });
      message.success('The LTI service has been deleted.');
      history.push('/lms/lti/v1p3');
    } catch (err) {
      message.error('An error occurred while deleting the lti service.');
    }
  }, [data, history, mutationLTIv1p3Delete]);
  return <Delete data={data?.ltiv1p3} handleDelete={handleDelete} />;
};

export default DeleteContainer;
