import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useMutationLTIv1p3Create } from 'gql/ltiv1p3';

import Create from './Create';

const CreateContainer = () => {
  const history = useHistory();
  const mutationLTIv1p3Create = useMutationLTIv1p3Create();
  const handleCreate = useCallback(
    async (values) => {
      const authorizationConfig = {
        method: 'JWK_SET',
        key: values.authorizationConfigKey,
      };
      try {
        const { data } = await mutationLTIv1p3Create({
          ...values,
          authorizationConfig,
        });
        message.success('The LTI service has been created.');
        history.push(`/lms/lti/v1p3/read/${data.ltiv1p3Create.id}`);
      } catch (err) {
        message.error('An error occurred while creating the LTI service.');
      }
    },
    [history, mutationLTIv1p3Create],
  );
  return <Create handleCreate={handleCreate} />;
};

export default CreateContainer;
