import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useMutationGARCreate } from 'gql/gar';

import Create from './Create';

const CreateContainer = () => {
  const history = useHistory();
  const mutationGARCreate = useMutationGARCreate();
  const handleCreate = useCallback(
    async (values) => {
      try {
        const { data } = await mutationGARCreate(values);
        message.success('The GAR subscription has been created.');
        history.push(`/partners/gar/read/${data.GARCreate.id}`);
      } catch (err) {
        message.error('An error occurred while creating the GAR subscription.');
      }
    },
    [history, mutationGARCreate],
  );
  return <Create handleCreate={handleCreate} />;
};

export default CreateContainer;
