import { SendOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input, Select, Switch } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const Upsert = ({ data, handleSubmit, organizations }) => (
  <Form
    initialValues={{
      organizationId: data.organizationId || null,
      name: data.name || '',
      specifier: data.specifier || '',
      domains: data.domains || [],
      adminEmail: data.adminEmail || '',
      profile: data.profile ? JSON.stringify(data.profile, null, 2) : '',
      configuration: data.configuration
        ? JSON.stringify(data.configuration, null, 2)
        : '',
      isPublic: data.isPublic || false,
    }}
    layout="vertical"
    onFinish={handleSubmit}
  >
    <Form.Item
      label="Display name of the CAS strategy (e.g. Wooclap)."
      name="name"
      rules={[{ required: true }]}
    >
      <Input placeholder="Wooclap" />
    </Form.Item>
    <Form.Item
      extra={
        <Alert
          banner
          message="Do not change the identifier of the CAS strategy once it is defined unless you know what you are doing."
          type="warning"
        />
      }
      label="Identifier of the CAS strategy for internal use. Please use the domain name of the client (e.g. wooclap.com)."
      name="specifier"
      rules={[{ required: true }]}
    >
      <Input placeholder="wooclap.com" />
    </Form.Item>
    <Form.Item
      extra={
        <Alert
          banner
          message="You can use * as a joker to accept multiple subdomains at once (e.g. wooclap.com, *.wooclap.com)."
          type="info"
        />
      }
      label="Trusted domain names of the CAS strategy that will be used to authorize email addresses."
      name="domains"
      rules={[{ required: true }]}
    >
      <Select mode="tags" placeholder="wooclap.com, *.wooclap.com" />
    </Form.Item>
    <Form.Item
      label="Organization associated with the CAS strategy."
      name="organizationId"
    >
      <Select
        allowClear
        showSearch
        filterOption={(input, option) =>
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        options={[
          {
            label: 'Not attached to any organization',
            value: null,
          },
          ...organizations.map((organization) => ({
            label: organization.name,
            value: organization.id,
          })),
        ]}
      />
    </Form.Item>
    <Form.Item
      label="Email address of the administrator of the CAS strategy (e.g. dev@wooclap.com)"
      name="adminEmail"
    >
      <Input placeholder="dev@wooclap.com" />
    </Form.Item>
    <Form.Item
      label="Profile mapping between the CAS provider and Wooflash. This string is converted to a JSON object."
      name="profile"
      rules={[{ required: true }]}
    >
      <Input.TextArea autoSize placeholder='{ "identifier": "username" }' />
    </Form.Item>
    <Form.Item
      label="Dedicated configuration of the CAS strategy. This string is converted to a JSON object."
      name="configuration"
      rules={[{ required: true }]}
    >
      <Input.TextArea autoSize placeholder='{ "ssoBaseURL": "https://..." }' />
    </Form.Item>
    <Form.Item
      label="Make this CAS strategy public and display it on the login page of Wooflash."
      name="isPublic"
      valuePropName="checked"
    >
      <Switch />
    </Form.Item>
    <Button htmlType="submit" icon={<SendOutlined />} type="primary">
      Submit
    </Button>
  </Form>
);

Upsert.propTypes = {
  data: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  organizations: PropTypes.arrayOf(PropTypes.object),
};

Upsert.defaultProps = {
  data: {},
  organizations: [],
};

export default Upsert;
