import { EyeOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row, Table } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { sortText, searchText } from 'lib/table';

const columns = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    ...sortText('title'),
    ...searchText('title'),
  },
  {
    title: 'AccessCode',
    dataIndex: 'accessCode',
    key: 'accessCode',
    ...sortText('accessCode'),
    ...searchText('accessCode'),
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
    ...sortText('category'),
    ...searchText('category'),
  },
  {
    title: 'Language',
    dataIndex: 'language',
    key: 'language',
    ...sortText('language'),
    ...searchText('language'),
  },
  {
    title: 'Destination',
    dataIndex: 'destination',
    key: 'destination',
    ...sortText('destination'),
    ...searchText('destination'),
  },
  {
    title: '',
    key: 'read',
    width: 100,
    // eslint-disable-next-line react/prop-types
    render: ({ id }) => (
      <Link to={`/public-courses/read/${id}`}>
        <Button icon={<EyeOutlined />}>View</Button>
      </Link>
    ),
  },
];

const List = ({ data }) => (
  <>
    <Row gutter={16}>
      <Col flex="auto">
        <h1>List all public courses</h1>
      </Col>
    </Row>
    <Divider />
    <Table columns={columns} dataSource={data} loading={!data} />
  </>
);

List.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

List.defaultProps = {
  data: undefined,
};

export default List;
