import { SendOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const Merge = ({ handleMerge }) => (
  <Form
    initialValues={{
      fromId: '',
      toId: '',
    }}
    layout="vertical"
    onFinish={handleMerge}
  >
    <Form.Item
      label="ID of the account that will be deleted after the merge"
      name="fromId"
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="ID of the account that will remain after the merge"
      name="toId"
    >
      <Input />
    </Form.Item>
    <Button htmlType="submit" icon={<SendOutlined />} type="primary">
      Submit
    </Button>
  </Form>
);

Merge.propTypes = {
  handleMerge: PropTypes.func.isRequired,
};

export default Merge;
