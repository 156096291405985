import React from 'react';
import { useParams } from 'react-router-dom';

import { useQuerySAML } from 'gql/saml';

import Read from './Read';

const ReadContainer = () => {
  const { id } = useParams();
  const { data } = useQuerySAML({
    variables: {
      samlId: id,
    },
  });
  return <Read data={data?.SAML} />;
};

export default ReadContainer;
