import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useQueryAccount, useMutationAccountUpdate } from 'gql/account';

import Update from './Update';

const UpdateContainer = () => {
  const history = useHistory();
  const { id } = useParams();
  const { data } = useQueryAccount({
    variables: {
      accountId: id,
    },
  });
  const mutationAccountUpdate = useMutationAccountUpdate();
  const handleUpdate = useCallback(
    async (values) => {
      try {
        await mutationAccountUpdate({
          ...values,
          accountId: data.account.id,
        });
        message.success('The account has been updated.');
        history.push(`/accounts/read/${data.account.id}`);
      } catch (err) {
        message.error('An error occurred while updating the account.');
      }
    },
    [data, history, mutationAccountUpdate],
  );
  return <Update data={data?.account} handleUpdate={handleUpdate} />;
};

export default UpdateContainer;
