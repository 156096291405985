import { ApolloProvider } from '@apollo/client';
import { Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import storeJS from 'store';

import App from 'App';
import Auth from 'Auth';
import { useQueryMe } from 'gql/me';
import apollo from 'lib/apollo';
import Context from 'lib/context';

const Internal = () => {
  const [isLoading, setLoading] = useState(true);
  const [account, setAccount] = useState(null);
  const { refetch } = useQueryMe({
    client: apollo,
    skip: !storeJS.get('token'),
  });
  const handleLogIn = useCallback(async () => {
    try {
      setLoading(true);
      const {
        data: { me },
      } = await refetch();
      setAccount(me);
    } catch (err) {
      //
    } finally {
      setLoading(false);
    }
  }, [refetch]);
  const handleLogOut = useCallback(() => {
    setAccount(null);
    storeJS.set('token', null);
    storeJS.remove('token');
  }, []);
  const hasAccess = useCallback(
    (right) =>
      account
        ? account.accessRights.includes(`admin-${right}`) ||
          account.accessRights.includes('admin-su')
        : false,
    [account],
  );
  useEffect(() => {
    handleLogIn();
  }, [handleLogIn]);
  return (
    <React.StrictMode>
      <ApolloProvider client={apollo}>
        <Router>
          <Context.Provider
            value={{
              isLoggedIn: !!account,
              account,
              handleLogIn,
              handleLogOut,
              hasAccess,
            }}
          >
            {isLoading ? <Spin size="large" /> : account ? <App /> : <Auth />}
          </Context.Provider>
        </Router>
      </ApolloProvider>
    </React.StrictMode>
  );
};

ReactDOM.render(<Internal />, document.getElementById('app-root'));
