import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useMutationCASUpdate, useQueryCAS } from 'gql/cas';

import Update from './Update';

const UpdateContainer = () => {
  const history = useHistory();
  const { id } = useParams();
  const { data } = useQueryCAS({
    variables: {
      casId: id,
    },
  });
  const mutationCASUpdate = useMutationCASUpdate();
  const handleUpdate = useCallback(
    async ({ profile, configuration, ...values }) => {
      try {
        await mutationCASUpdate({
          ...values,
          casId: data.CAS.id,
          profile: JSON.parse(profile),
          configuration: JSON.parse(configuration),
        });
        message.success('The CAS strategy has been updated.');
        history.push(`/auth/cas/read/${data.CAS.id}`);
      } catch (err) {
        message.error('An error occurred while updating the CAS strategy.');
      }
    },
    [data, history, mutationCASUpdate],
  );
  return <Update data={data?.CAS} handleUpdate={handleUpdate} />;
};

export default UpdateContainer;
