import { BugOutlined, CheckSquareOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row, Space } from 'antd';
import React from 'react';

const Facebook = () => (
  <>
    <Row gutter={16}>
      <Col flex="auto">
        <h1>Facebook</h1>
      </Col>
      <Col>
        <Space>
          <Button
            href={`${process.env.REACT_APP_API_URL}/api/auth/facebook/login?debug=true`}
            icon={<BugOutlined />}
          >
            Debug
          </Button>
          <Button
            href={`${process.env.REACT_APP_API_URL}/api/auth/facebook/login`}
            icon={<CheckSquareOutlined />}
            type="primary"
          >
            Test
          </Button>
        </Space>
      </Col>
    </Row>
    <Divider />
    <p>
      Facebook for Developers:{' '}
      <a
        href="https://developers.facebook.com/apps/963927893799783/fb-login/settings/"
        target="blank"
      >
        https://developers.facebook.com/apps/963927893799783/fb-login/settings/
      </a>
      .
    </p>
  </>
);

export default Facebook;
