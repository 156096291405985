import { EyeOutlined, LinkOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row, Space, Table } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { sortText, searchText, searchArray, renderArray } from 'lib/table';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    ...sortText('name'),
    ...searchText('name'),
  },
  {
    title: 'Specifier',
    dataIndex: 'specifier',
    key: 'specifier',
    ...sortText('specifier'),
    ...searchText('specifier'),
  },
  {
    title: 'Domains',
    dataIndex: 'domains',
    key: 'domains',
    ...searchArray('domains'),
    ...renderArray(),
  },
  {
    title: 'Organization',
    key: 'organization',
    // eslint-disable-next-line react/prop-types
    render: ({ organization }) => (organization ? organization.name : ''),
  },
  {
    title: '',
    key: 'read',
    width: 100,
    // eslint-disable-next-line react/prop-types
    render: ({ id }) => (
      <Link to={`/lms/lti/v1p1/read/${id}`}>
        <Button icon={<EyeOutlined />}>View</Button>
      </Link>
    ),
  },
];

const List = ({ data }) => (
  <>
    <Row gutter={16}>
      <Col flex="auto">
        <h1>List all LTI 1.1 services</h1>
      </Col>
      <Col>
        <Space>
          <Link to="/lms/lti/v1p1/create">
            <Button icon={<PlusOutlined />} type="primary">
              Create a new LTI 1.1 service
            </Button>
          </Link>
          <a
            href={`${process.env.REACT_APP_API_URL}/api/lti/v1p1/config.xml`}
            target="blank"
          >
            <Button icon={<LinkOutlined />}>View the configuration</Button>
          </a>
        </Space>
      </Col>
    </Row>
    <Divider />
    <Table columns={columns} dataSource={data} loading={!data} />
  </>
);

List.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

List.defaultProps = {
  data: undefined,
};

export default List;
