import React from 'react';

import { useQueryMoodles } from 'gql/moodle';

import List from './List';

const ListContainer = () => {
  const { data } = useQueryMoodles();
  return <List data={data?.moodles} />;
};

export default ListContainer;
