import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useQueryCoupon, useMutationCouponUpdate } from 'gql/coupon';

import Update from './Update';

const UpdateContainer = () => {
  const history = useHistory();
  const { id } = useParams();
  const { data } = useQueryCoupon({
    variables: {
      couponId: id,
    },
  });
  const mutationCouponUpdate = useMutationCouponUpdate();
  const handleUpdate = useCallback(
    async (values) => {
      try {
        await mutationCouponUpdate({
          ...values,
          couponId: data.coupon.id,
        });
        message.success('The coupon has been updated.');
        history.push(`/coupons/read/${data.coupon.id}`);
      } catch (err) {
        message.error('An error occurred while updating the coupon.');
      }
    },
    [data, history, mutationCouponUpdate],
  );
  return <Update data={data?.coupon} handleUpdate={handleUpdate} />;
};

export default UpdateContainer;
