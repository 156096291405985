import {
  ArrowLeftOutlined,
  DeleteOutlined,
  EditOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  Descriptions,
  Divider,
  Row,
  Space,
  Spin,
} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const Read = ({ data }) =>
  !data ? (
    <Spin />
  ) : (
    <>
      <Row gutter={16}>
        <Col>
          <Link to="/coupons">
            <Button icon={<ArrowLeftOutlined />}>Back</Button>
          </Link>
        </Col>
        <Col flex="auto">
          <h1>{`View a coupon - ${data.name}`}</h1>
        </Col>
        <Col>
          <Space>
            <Link to={`/coupons/update/${data.id}`}>
              <Button icon={<EditOutlined />} type="primary">
                Update
              </Button>
            </Link>
            <Link to={`/coupons/delete/${data.id}`}>
              <Button icon={<DeleteOutlined />} type="danger">
                Delete
              </Button>
            </Link>
          </Space>
        </Col>
      </Row>
      <Divider />
      <Descriptions bordered column={1}>
        <Descriptions.Item label="Name">{data.name}</Descriptions.Item>
        <Descriptions.Item label="Access code">
          {data.accessCode}
        </Descriptions.Item>
        <Descriptions.Item label="Plan">
          {data.plan ? (
            <Link to={`/plans/read/${data.plan.id}`}>
              <Button icon={<LinkOutlined />} type="dashed">
                {data.plan.name}
              </Button>
            </Link>
          ) : (
            'Not attached to any plan'
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Organization">
          {data.organization ? (
            <Link to={`/organizations/read/${data.organization.id}`}>
              <Button icon={<LinkOutlined />} type="dashed">
                {data.organization.name}
              </Button>
            </Link>
          ) : (
            'Not attached to any organization'
          )}
        </Descriptions.Item>
      </Descriptions>
      <Divider />
      <p>
        <Alert
          message="Information to provide to the client"
          description="This is the information the client will need in order to use the coupon."
          type="info"
          showIcon
        />
      </p>
      <Descriptions bordered column={1}>
        <Descriptions.Item label="Link to use the coupon">
          {`${process.env.REACT_APP_WEB_URL}/microlearning/${data.accessCode}`}
        </Descriptions.Item>
      </Descriptions>
    </>
  );

Read.propTypes = {
  data: PropTypes.object,
};

Read.defaultProps = {
  data: undefined,
};

export default Read;
