import React from 'react';
import { useParams } from 'react-router-dom';

import { useQueryPlan } from 'gql/plan';

import Read from './Read';

const ReadContainer = () => {
  const { id } = useParams();
  const { data } = useQueryPlan({
    variables: {
      planId: id,
    },
  });
  return <Read data={data?.plan} />;
};

export default ReadContainer;
