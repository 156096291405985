import { gql, useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';

const QUERY_MOODLES = gql`
  {
    moodles {
      id
      organizationId
      name
      specifier
      domains
      organization {
        id
        name
      }
    }
  }
`;

export function useQueryMoodles() {
  const { loading, data, ...props } = useQuery(QUERY_MOODLES);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

const QUERY_MOODLE = gql`
  query moodle($moodleId: ID!) {
    moodle(moodleId: $moodleId) {
      id
      organizationId
      name
      specifier
      domains
      adminEmail
      accessKeyId
      secretAccessKey
      organization {
        id
        name
      }
    }
  }
`;

export function useQueryMoodle(options) {
  const { loading, data, ...props } = useQuery(QUERY_MOODLE, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const MOODLE_CREATE = gql`
  mutation MoodleCreate(
    $organizationId: ID
    $name: String!
    $specifier: String!
    $domains: [String!]!
    $adminEmail: String
  ) {
    moodleCreate(
      organizationId: $organizationId
      name: $name
      specifier: $specifier
      domains: $domains
      adminEmail: $adminEmail
    ) {
      id
    }
  }
`;

export function useMutationMoodleCreate() {
  const [mutation] = useMutation(MOODLE_CREATE);
  return useCallback((variables) => mutation({ variables }), [mutation]);
}

export const MOODLE_UPDATE = gql`
  mutation MoodleUpdate(
    $moodleId: ID!
    $organizationId: ID
    $name: String
    $specifier: String
    $domains: [String!]
    $adminEmail: String
  ) {
    moodleUpdate(
      moodleId: $moodleId
      organizationId: $organizationId
      name: $name
      specifier: $specifier
      domains: $domains
      adminEmail: $adminEmail
    ) {
      id
    }
  }
`;

export function useMutationMoodleUpdate() {
  const [mutation] = useMutation(MOODLE_UPDATE);
  return useCallback((variables) => mutation({ variables }), [mutation]);
}

export const MOODLE_DELETE = gql`
  mutation MoodleDelete($moodleId: ID!) {
    moodleDelete(moodleId: $moodleId)
  }
`;

export function useMutationMoodleDelete() {
  const [mutation] = useMutation(MOODLE_DELETE);
  return useCallback((variables) => mutation({ variables }), [mutation]);
}
