import { gql, useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';

const QUERY_PUBLIC_COURSES = gql`
  {
    publicCourses {
      id
      accessCode
      title
      isPublic
      category
      language
      destination
      tags {
        id
        title
      }
    }
  }
`;

export function useQueryPublicCourses() {
  const { loading, data, ...props } = useQuery(QUERY_PUBLIC_COURSES);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

const QUERY_PUBLIC_COURSE = gql`
  query publicCourse($courseId: ID!) {
    publicCourse(courseId: $courseId) {
      id
      accessCode
      title
      introduction
      isPublic
      category
      language
      destination
      tags {
        id
        title
      }
      author {
        id
        firstName
        lastName
      }
    }
  }
`;

export function useQueryPublicCourse(options) {
  const { loading, data, ...props } = useQuery(QUERY_PUBLIC_COURSE, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const COURSE_UPDATE_ADMIN = gql`
  mutation courseUpdateAdmin(
    $courseId: ID!
    $isPublic: Boolean
    $category: String
    $language: String
    $destination: String
    $tagIds: [ID]
  ) {
    courseUpdateAdmin(
      courseId: $courseId
      isPublic: $isPublic
      category: $category
      language: $language
      destination: $destination
      tagIds: $tagIds
    ) {
      id
    }
  }
`;

export function useMutationCourseUpdate() {
  const [mutation] = useMutation(COURSE_UPDATE_ADMIN);
  return useCallback(
    (variables) =>
      mutation({
        variables,
      }),
    [mutation],
  );
}
