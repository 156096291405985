import React, { useCallback, useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Context from 'lib/context';

import App from './App';

const AppContainer = () => {
  const { hasAccess } = useContext(Context);
  const [isCollapsed, setCollapsed] = useState(false);
  const handleCollapseToggle = useCallback(() => {
    setCollapsed((current) => !current);
  }, []);
  const { pathname } = useLocation();
  const [, key1, key2] = pathname.split('/');
  const openMenus = [`/${key1}`];
  const selectedMenus = [`/${key1}`, `/${key1}/${key2}`];
  const history = useHistory();
  const handleNavigation = useCallback(
    ({ key }) => {
      history.push(key);
    },
    [history],
  );
  return (
    <App
      handleCollapseToggle={handleCollapseToggle}
      handleNavigation={handleNavigation}
      hasAccess={hasAccess}
      isCollapsed={isCollapsed}
      openMenus={openMenus}
      selectedMenus={selectedMenus}
    />
  );
};

export default AppContainer;
