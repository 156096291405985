import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  useQueryTranslation,
  useMutationTranslationUpdate,
} from 'gql/translation';

import Update from './Update';

const UpdateContainer = () => {
  const history = useHistory();
  const { id } = useParams();
  const { data } = useQueryTranslation({
    variables: {
      translationId: id,
    },
  });
  const mutationTranslationUpdate = useMutationTranslationUpdate();
  const handleUpdate = useCallback(
    async (values) => {
      try {
        await mutationTranslationUpdate({
          ...values,
          translationId: data.translation.id,
        });
        message.success('The translation has been updated.');
        history.push(`/translations/read/${data.translation.id}`);
      } catch (err) {
        message.error('An error occurred while updating the translation.');
      }
    },
    [data, history, mutationTranslationUpdate],
  );
  return <Update data={data?.translation} handleUpdate={handleUpdate} />;
};

export default UpdateContainer;
