import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  useMutationOrganizationPopulate,
  useQueryOrganization,
} from 'gql/organization';

import Populate from './Populate';

const PopulateContainer = () => {
  const history = useHistory();
  const { id } = useParams();
  const { data } = useQueryOrganization({
    variables: {
      organizationId: id,
    },
  });
  const mutationOrganizationPopulate = useMutationOrganizationPopulate();
  const handlePopulate = useCallback(async () => {
    try {
      await mutationOrganizationPopulate({
        organizationId: data.organization.id,
      });
      message.success('The organization has been populated.');
      history.push(`/organizations/read/${data.organization.id}`);
    } catch (err) {
      message.error('An error occurred while populating the organization.');
    }
  }, [data, history, mutationOrganizationPopulate]);
  return <Populate data={data?.organization} handlePopulate={handlePopulate} />;
};

export default PopulateContainer;
