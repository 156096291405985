import {
  ArrowLeftOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  EditOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Descriptions,
  Divider,
  Row,
  Space,
  Spin,
  Tag,
} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const Read = ({ data, loading }) =>
  !data ? (
    loading ? (
      <Spin />
    ) : (
      <Row gutter={16}>
        <Col>
          <Link to="/public-courses">
            <Button icon={<ArrowLeftOutlined />}>Back</Button>
          </Link>
        </Col>
        <Col flex="auto">
          <h1>This course is no longer public</h1>
        </Col>
      </Row>
    )
  ) : (
    <>
      <Row gutter={16}>
        <Col>
          <Link to="/public-courses">
            <Button icon={<ArrowLeftOutlined />}>Back</Button>
          </Link>
        </Col>
        <Col flex="auto">
          <h1>{`View a public course - ${data.title}`}</h1>
        </Col>
        <Col>
          <Space>
            <Link to={`/public-courses/update/${data.id}`}>
              <Button icon={<EditOutlined />} type="primary">
                Update
              </Button>
            </Link>
          </Space>
        </Col>
      </Row>
      <Divider />
      <Descriptions bordered column={1}>
        <Descriptions.Item label="AccessCode">
          {data.accessCode}
        </Descriptions.Item>
        <Descriptions.Item label="Introduction">
          {data.introduction}
        </Descriptions.Item>
        <Descriptions.Item label="Is public?">
          {data.isPublic ? (
            <Tag icon={<CheckCircleOutlined />} color="success">
              Yes
            </Tag>
          ) : (
            <Tag icon={<CloseCircleOutlined />} color="error">
              No
            </Tag>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Category">{data.category}</Descriptions.Item>
        <Descriptions.Item label="Language">{data.language}</Descriptions.Item>
        <Descriptions.Item label="For who?">
          {data.destination}
        </Descriptions.Item>
        <Descriptions.Item label="Tags">
          {data.tags.map((tag, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Tag key={index}>{tag.title}</Tag>
          ))}
        </Descriptions.Item>
        <Descriptions.Item label="Author">
          {`${data.author.firstName} ${data.author.lastName}`}
        </Descriptions.Item>
      </Descriptions>
    </>
  );

Read.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool.isRequired,
};

Read.defaultProps = {
  data: undefined,
};

export default Read;
