import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useMutationAuthRecoverPassword } from 'gql/auth';

import Password from './Password';

const PasswordContainer = () => {
  const history = useHistory();
  const mutationAuthRecoverPassword = useMutationAuthRecoverPassword();
  const handlePasswordRecover = useCallback(
    async (values) => {
      try {
        await mutationAuthRecoverPassword(values);
        message.success('The password recovery email has been sent.');
        history.push('/auth/local');
      } catch (err) {
        message.error(
          'An error occurred while sending the password recovery email.',
        );
      }
    },
    [history, mutationAuthRecoverPassword],
  );
  return <Password handlePasswordRecover={handlePasswordRecover} />;
};

export default PasswordContainer;
