import React from 'react';

const Home = () => (
  <>
    <h1>Home</h1>
    <p>Welcome to the administration panel of Wooflash.</p>
  </>
);

export default Home;
