import React from 'react';

import { useQueryGARs } from 'gql/gar';

import List from './List';

const ListContainer = () => {
  const { data } = useQueryGARs();
  return <List data={data?.GARs} />;
};

export default ListContainer;
