import React from 'react';
import { useParams } from 'react-router-dom';

import { useQueryTranslation } from 'gql/translation';

import Read from './Read';

const ReadContainer = () => {
  const { id } = useParams();
  const { data } = useQueryTranslation({
    variables: {
      translationId: id,
    },
  });
  return <Read data={data?.translation} />;
};

export default ReadContainer;
