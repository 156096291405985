import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useMutationEduGAINUpdate, useQueryEduGAIN } from 'gql/edugain';

import Update from './Update';

const UpdateContainer = () => {
  const history = useHistory();
  const { id } = useParams();
  const { data } = useQueryEduGAIN({
    variables: {
      edugainId: id,
    },
  });
  const mutationEduGAINUpdate = useMutationEduGAINUpdate();
  const handleUpdate = useCallback(
    async ({ overrides, ...values }) => {
      try {
        await mutationEduGAINUpdate({
          ...values,
          edugainId: data.eduGAIN.id,
          overrides: JSON.parse(overrides),
        });
        message.success('The eduGAIN strategy has been updated.');
        history.push(`/auth/edugain/read/${data.eduGAIN.id}`);
      } catch (err) {
        message.error('An error occurred while updating the eduGAIN strategy.');
      }
    },
    [data, history, mutationEduGAINUpdate],
  );
  return <Update data={data?.eduGAIN} handleUpdate={handleUpdate} />;
};

export default UpdateContainer;
