import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  useMutationTranslationCreate,
  useQueryTranslation,
} from 'gql/translation';

import Duplicate from './Duplicate';

const DuplicateContainer = () => {
  const history = useHistory();
  const { id } = useParams();
  const { data } = useQueryTranslation({
    variables: {
      translationId: id,
    },
  });
  const mutationTranslationCreate = useMutationTranslationCreate();
  const handleDuplicate = useCallback(
    async (values) => {
      try {
        const { data: d } = await mutationTranslationCreate(values);
        message.success('The translation has been created.');
        history.push(`/translations/read/${d.translationCreate.id}`);
      } catch (err) {
        message.error('An error occurred while creating the translation.');
      }
    },
    [history, mutationTranslationCreate],
  );
  return (
    <Duplicate data={data?.translation} handleDuplicate={handleDuplicate} />
  );
};

export default DuplicateContainer;
