import { EyeOutlined, MergeCellsOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row, Space, Table } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { sortText, searchText } from 'lib/table';

const columns = [
  {
    title: 'First name',
    dataIndex: 'firstName',
    key: 'firstName',
    ...sortText('firstName'),
    ...searchText('firstName'),
  },
  {
    title: 'Last name',
    dataIndex: 'lastName',
    key: 'lastName',
    ...sortText('lastName'),
    ...searchText('lastName'),
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    ...sortText('email'),
    ...searchText('email'),
  },
  {
    title: 'Username',
    key: 'username',
    // eslint-disable-next-line react/prop-types
    render: ({ username }) => username.substring(0, 30),
    ...sortText('username'),
    ...searchText('username'),
  },
  {
    title: '',
    key: 'read',
    width: 100,
    // eslint-disable-next-line react/prop-types
    render: ({ id }) => (
      <Link to={`/accounts/read/${id}`}>
        <Button icon={<EyeOutlined />}>View</Button>
      </Link>
    ),
  },
];

const List = ({ data }) => (
  <>
    <Row gutter={16}>
      <Col flex="auto">
        <h1>List all accounts</h1>
      </Col>
      <Col>
        <Space>
          <Link to="/accounts/merge">
            <Button icon={<MergeCellsOutlined />}>Merge accounts</Button>
          </Link>
        </Space>
      </Col>
    </Row>
    <Divider />
    <Table columns={columns} dataSource={data} loading={!data} />
  </>
);

List.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

List.defaultProps = {
  data: undefined,
};

export default List;
