import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import AuthActive from './Auth/Active';

import AccountsList from './Accounts/List';
import AccountsMerge from './Accounts/Merge';
import AccountsRead from './Accounts/Read';
import AccountsUpdate from './Accounts/Update';

import PublicCoursesList from './PublicCourses/List';
import PublicCoursesRead from './PublicCourses/Read';
import PublicCoursesUpdate from './PublicCourses/Update';

import AuthCASList from './Auth/CAS/List';
import AuthCASCreate from './Auth/CAS/Create';
import AuthCASRead from './Auth/CAS/Read';
import AuthCASUpdate from './Auth/CAS/Update';
import AuthCASDelete from './Auth/CAS/Delete';

import AuthEduGAINLList from './Auth/EduGAIN/List';
import AuthEduGAINRead from './Auth/EduGAIN/Read';
import AuthEduGAINUpdate from './Auth/EduGAIN/Update';
import AuthEduGAINUpdateAll from './Auth/EduGAIN/UpdateAll';

import AuthAzureAD from './Auth/AzureAD';
import AuthFacebook from './Auth/Facebook';
import AuthGoogle from './Auth/Google';
import AuthInternal from './Auth/Internal';

import AuthLocalHome from './Auth/Local/Home';
import AuthLocalPassword from './Auth/Local/Password';
import AuthLocalVerification from './Auth/Local/Verification';

import AuthSAMLList from './Auth/SAML/List';
import AuthSAMLCreate from './Auth/SAML/Create';
import AuthSAMLRead from './Auth/SAML/Read';
import AuthSAMLUpdate from './Auth/SAML/Update';
import AuthSAMLDelete from './Auth/SAML/Delete';

import Home from './Home';

import Logout from './Logout';

import LMSMoodleList from './LMS/Moodle/List';
import LMSMoodleCreate from './LMS/Moodle/Create';
import LMSMoodleRead from './LMS/Moodle/Read';
import LMSMoodleUpdate from './LMS/Moodle/Update';
import LMSMoodleDelete from './LMS/Moodle/Delete';

import LMSLTIv1p1List from './LMS/LTIv1p1/List';
import LMSLTIv1p1Create from './LMS/LTIv1p1/Create';
import LMSLTIv1p1Read from './LMS/LTIv1p1/Read';
import LMSLTIv1p1Update from './LMS/LTIv1p1/Update';
import LMSLTIv1p1Delete from './LMS/LTIv1p1/Delete';

import LMSLTIv1p3List from './LMS/LTIv1p3/List';
import LMSLTIv1p3Create from './LMS/LTIv1p3/Create';
import LMSLTIv1p3Read from './LMS/LTIv1p3/Read';
import LMSLTIv1p3Update from './LMS/LTIv1p3/Update';
import LMSLTIv1p3Delete from './LMS/LTIv1p3/Delete';

import OrganizationsList from './Organizations/List';
import OrganizationsCreate from './Organizations/Create';
import OrganizationsRead from './Organizations/Read';
import OrganizationsUpdate from './Organizations/Update';
import OrganizationsDelete from './Organizations/Delete';
import OrganizationsPopulate from './Organizations/Populate';

import CouponsList from './Coupons/List';
import CouponsCreate from './Coupons/Create';
import CouponsRead from './Coupons/Read';
import CouponsUpdate from './Coupons/Update';
import CouponsDelete from './Coupons/Delete';

import Marketing from './Marketing';

import PlansList from './Plans/List';
import PlansCreate from './Plans/Create';
import PlansRead from './Plans/Read';
import PlansUpdate from './Plans/Update';
import PlansDelete from './Plans/Delete';

import PartnersGARList from './Partners/GAR/List';
import PartnersGARListInstitutions from './Partners/GAR/ListInstitutions';
import PartnersGARListSubscriptions from './Partners/GAR/ListSubscriptions';
import PartnersGARCreate from './Partners/GAR/Create';
import PartnersGARRead from './Partners/GAR/Read';
import PartnersGARUpdate from './Partners/GAR/Update';
import PartnersGARDelete from './Partners/GAR/Delete';

import PartnersTNECanopeList from './Partners/TNECanope/List';
import PartnersTNECanopeRead from './Partners/TNECanope/Read';

import Tools from './Tools';

import TranslationsList from './Translations/List';
import TranslationsCreate from './Translations/Create';
import TranslationsRead from './Translations/Read';
import TranslationsUpdate from './Translations/Update';
import TranslationsDelete from './Translations/Delete';
import TranslationsDuplicate from './Translations/Duplicate';
import TranslationsImport from './Translations/Import';
import TranslationsSync from './Translations/Sync';

const Router = () => (
  <Switch>
    <Route exact path="/accounts" component={AccountsList} />
    <Route exact path="/accounts/merge" component={AccountsMerge} />
    <Route exact path="/accounts/read/:id" component={AccountsRead} />
    <Route exact path="/accounts/update/:id" component={AccountsUpdate} />

    <Route exact path="/public-courses" component={PublicCoursesList} />
    <Route
      exact
      path="/public-courses/read/:id"
      component={PublicCoursesRead}
    />
    <Route
      exact
      path="/public-courses/update/:id"
      component={PublicCoursesUpdate}
    />

    <Route exact path="/auth/active" component={AuthActive} />

    <Route exact path="/auth/cas" component={AuthCASList} />
    <Route exact path="/auth/cas/create" component={AuthCASCreate} />
    <Route exact path="/auth/cas/read/:id" component={AuthCASRead} />
    <Route exact path="/auth/cas/update/:id" component={AuthCASUpdate} />
    <Route exact path="/auth/cas/delete/:id" component={AuthCASDelete} />

    <Route exact path="/auth/edugain" component={AuthEduGAINLList} />
    <Route exact path="/auth/edugain/read/:id" component={AuthEduGAINRead} />
    <Route
      exact
      path="/auth/edugain/update/:id"
      component={AuthEduGAINUpdate}
    />
    <Route
      exact
      path="/auth/edugain/update-all"
      component={AuthEduGAINUpdateAll}
    />

    <Route exact path="/auth/azure-ad" component={AuthAzureAD} />
    <Route exact path="/auth/facebook" component={AuthFacebook} />
    <Route exact path="/auth/google" component={AuthGoogle} />
    <Route exact path="/auth/internal" component={AuthInternal} />

    <Route exact path="/auth/local" component={AuthLocalHome} />
    <Route exact path="/auth/local/password" component={AuthLocalPassword} />
    <Route
      exact
      path="/auth/local/verification"
      component={AuthLocalVerification}
    />

    <Route exact path="/auth/saml" component={AuthSAMLList} />
    <Route exact path="/auth/saml/create" component={AuthSAMLCreate} />
    <Route exact path="/auth/saml/read/:id" component={AuthSAMLRead} />
    <Route exact path="/auth/saml/update/:id" component={AuthSAMLUpdate} />
    <Route exact path="/auth/saml/delete/:id" component={AuthSAMLDelete} />

    <Route exact path="/" component={Home} />

    <Route exact path="/logout" component={Logout} />

    <Route exact path="/lms/moodle" component={LMSMoodleList} />
    <Route exact path="/lms/moodle/create" component={LMSMoodleCreate} />
    <Route exact path="/lms/moodle/read/:id" component={LMSMoodleRead} />
    <Route exact path="/lms/moodle/update/:id" component={LMSMoodleUpdate} />
    <Route exact path="/lms/moodle/delete/:id" component={LMSMoodleDelete} />

    <Route exact path="/lms/lti/v1p1" component={LMSLTIv1p1List} />
    <Route exact path="/lms/lti/v1p1/create" component={LMSLTIv1p1Create} />
    <Route exact path="/lms/lti/v1p1/read/:id" component={LMSLTIv1p1Read} />
    <Route exact path="/lms/lti/v1p1/update/:id" component={LMSLTIv1p1Update} />
    <Route exact path="/lms/lti/v1p1/delete/:id" component={LMSLTIv1p1Delete} />

    <Route exact path="/lms/lti/v1p3" component={LMSLTIv1p3List} />
    <Route exact path="/lms/lti/v1p3/create" component={LMSLTIv1p3Create} />
    <Route exact path="/lms/lti/v1p3/read/:id" component={LMSLTIv1p3Read} />
    <Route exact path="/lms/lti/v1p3/update/:id" component={LMSLTIv1p3Update} />
    <Route exact path="/lms/lti/v1p3/delete/:id" component={LMSLTIv1p3Delete} />

    <Route exact path="/marketing" component={Marketing} />

    <Route exact path="/partners/gar" component={PartnersGARList} />
    <Route
      exact
      path="/partners/gar/institutions"
      component={PartnersGARListInstitutions}
    />
    <Route
      exact
      path="/partners/gar/subscriptions"
      component={PartnersGARListSubscriptions}
    />
    <Route exact path="/partners/gar/create" component={PartnersGARCreate} />
    <Route exact path="/partners/gar/read/:id" component={PartnersGARRead} />
    <Route
      exact
      path="/partners/gar/update/:id"
      component={PartnersGARUpdate}
    />
    <Route
      exact
      path="/partners/gar/delete/:id"
      component={PartnersGARDelete}
    />

    <Route exact path="/partners/tnecanope" component={PartnersTNECanopeList} />
    <Route
      exact
      path="/partners/tnecanope/read/:id"
      component={PartnersTNECanopeRead}
    />

    <Route exact path="/organizations" component={OrganizationsList} />
    <Route exact path="/organizations/create" component={OrganizationsCreate} />
    <Route exact path="/organizations/read/:id" component={OrganizationsRead} />
    <Route
      exact
      path="/organizations/update/:id"
      component={OrganizationsUpdate}
    />
    <Route
      exact
      path="/organizations/delete/:id"
      component={OrganizationsDelete}
    />
    <Route
      exact
      path="/organizations/populate/:id"
      component={OrganizationsPopulate}
    />

    <Route exact path="/plans" component={PlansList} />
    <Route exact path="/plans/create" component={PlansCreate} />
    <Route exact path="/plans/read/:id" component={PlansRead} />
    <Route exact path="/plans/update/:id" component={PlansUpdate} />
    <Route exact path="/plans/delete/:id" component={PlansDelete} />

    <Route exact path="/coupons" component={CouponsList} />
    <Route exact path="/coupons/create" component={CouponsCreate} />
    <Route exact path="/coupons/read/:id" component={CouponsRead} />
    <Route exact path="/coupons/update/:id" component={CouponsUpdate} />
    <Route exact path="/coupons/delete/:id" component={CouponsDelete} />

    <Route exact path="/tools" component={Tools} />

    <Route exact path="/translations" component={TranslationsList} />
    <Route exact path="/translations/create" component={TranslationsCreate} />
    <Route exact path="/translations/read/:id" component={TranslationsRead} />
    <Route
      exact
      path="/translations/update/:id"
      component={TranslationsUpdate}
    />
    <Route
      exact
      path="/translations/delete/:id"
      component={TranslationsDelete}
    />
    <Route
      exact
      path="/translations/duplicate/:id"
      component={TranslationsDuplicate}
    />
    <Route exact path="/translations/import" component={TranslationsImport} />
    <Route exact path="/translations/sync" component={TranslationsSync} />

    <Route render={() => <Redirect to="/" />} />
  </Switch>
);

export default Router;
