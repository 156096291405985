import {
  BankOutlined,
  BlockOutlined,
  BookOutlined,
  CloudSyncOutlined,
  CompassOutlined,
  DollarCircleOutlined,
  ExperimentOutlined,
  FlagOutlined,
  HomeOutlined,
  LockOutlined,
  LogoutOutlined,
  MailOutlined,
  PercentageOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import Router from './Router';
import './App.scss';

const App = ({
  handleCollapseToggle,
  handleNavigation,
  hasAccess,
  isCollapsed,
  openMenus,
  selectedMenus,
}) => (
  <Layout className="App-container">
    <Layout.Sider
      collapsed={isCollapsed}
      collapsible
      onCollapse={handleCollapseToggle}
      theme="dark"
    >
      {isCollapsed ? (
        <img alt="" className="App-logo_sm" src="/images/logo.svg" />
      ) : (
        <img alt="" className="App-logo_lg" src="/images/logo.svg" />
      )}
      <Menu
        mode="inline"
        onClick={handleNavigation}
        defaultOpenKeys={openMenus}
        selectedKeys={selectedMenus}
        theme="dark"
      >
        <Menu.Item key="/" icon={<HomeOutlined />}>
          Home
        </Menu.Item>
        <Menu.Item key="/tools" icon={<ExperimentOutlined />}>
          Tools
        </Menu.Item>
        {hasAccess('plan') && (
          <Menu.Item key="/plans" icon={<DollarCircleOutlined />}>
            Plans
          </Menu.Item>
        )}
        {hasAccess('organization') && (
          <Menu.Item key="/organizations" icon={<BankOutlined />}>
            Organizations
          </Menu.Item>
        )}
        {hasAccess('coupon') && (
          <Menu.Item key="/coupons" icon={<PercentageOutlined />}>
            Coupons
          </Menu.Item>
        )}
        {hasAccess('account') && (
          <Menu.Item key="/accounts" icon={<UserOutlined />}>
            Accounts
          </Menu.Item>
        )}
        {hasAccess('course') && (
          <Menu.Item key="/public-courses" icon={<BookOutlined />}>
            Public courses
          </Menu.Item>
        )}
        <Menu.SubMenu
          key="/auth"
          icon={<LockOutlined />}
          title="Authentication"
        >
          {hasAccess('sso') && [
            <Menu.Item key="/auth/active">Active SSOs</Menu.Item>,
            <Menu.Item key="/auth/cas">CAS</Menu.Item>,
            <Menu.Item key="/auth/edugain">eduGAIN</Menu.Item>,
            <Menu.Item key="/auth/saml">SAML</Menu.Item>,
          ]}
          <Menu.Item key="/auth/azure-ad">AzureAD</Menu.Item>
          <Menu.Item key="/auth/facebook">Facebook</Menu.Item>
          <Menu.Item key="/auth/google">Google</Menu.Item>
          <Menu.Item key="/auth/internal">Internal</Menu.Item>
          <Menu.Item key="/auth/local">Local</Menu.Item>
        </Menu.SubMenu>
        {hasAccess('lms') && (
          <Menu.SubMenu key="/lms" icon={<CloudSyncOutlined />} title="LMS">
            <Menu.Item key="/lms/moodle">Moodle</Menu.Item>,
            <Menu.Item key="/lms/lti/v1p1">LTI 1.1</Menu.Item>,
            <Menu.Item key="/lms/lti/v1p3">LTI 1.3</Menu.Item>,
          </Menu.SubMenu>
        )}
        {hasAccess('gar') && (
          <Menu.SubMenu
            key="/partners"
            icon={<BlockOutlined />}
            title="Partners"
          >
            <Menu.Item key="/partners/gar" icon={<CompassOutlined />}>
              GAR
            </Menu.Item>
            <Menu.Item key="/partners/tnecanope" icon={<CompassOutlined />}>
              TNE Canope
            </Menu.Item>
          </Menu.SubMenu>
        )}
        {hasAccess('translation') && (
          <Menu.Item key="/translations" icon={<FlagOutlined />}>
            Translations
          </Menu.Item>
        )}
        {hasAccess('marketing') && (
          <Menu.Item key="/marketing" icon={<MailOutlined />}>
            Marketing
          </Menu.Item>
        )}
        <Menu.Item key="/logout" icon={<LogoutOutlined />}>
          Log out
        </Menu.Item>
      </Menu>
    </Layout.Sider>
    <Layout.Content className="App-content">
      <Router />
    </Layout.Content>
  </Layout>
);

App.propTypes = {
  handleCollapseToggle: PropTypes.func.isRequired,
  handleNavigation: PropTypes.func.isRequired,
  hasAccess: PropTypes.func.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
  openMenus: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedMenus: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default App;
