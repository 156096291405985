import React from 'react';

import { useQueryGARSubscriptions } from 'gql/gar';

import ListSubscriptions from './ListSubscriptions';

const ListSubscriptionsContainer = () => {
  const { data } = useQueryGARSubscriptions();
  return <ListSubscriptions data={data?.GARSubscriptions} />;
};

export default ListSubscriptionsContainer;
