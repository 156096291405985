import { SendOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const Upsert = ({ data, handleSubmit }) => (
  <Form
    initialValues={{
      name: data.name || '',
    }}
    layout="vertical"
    onFinish={handleSubmit}
  >
    <Form.Item
      label="Display name of the plan (e.g. Unlimited)."
      name="name"
      rules={[{ required: true }]}
    >
      <Input placeholder="Wooclap" />
    </Form.Item>
    <Button htmlType="submit" icon={<SendOutlined />} type="primary">
      Submit
    </Button>
  </Form>
);

Upsert.propTypes = {
  data: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
};

Upsert.defaultProps = {
  data: {},
};

export default Upsert;
