import React from 'react';

import { useQueryCASs } from 'gql/cas';

import List from './List';

const ListContainer = () => {
  const { data } = useQueryCASs();
  return <List data={data?.CASs} />;
};

export default ListContainer;
