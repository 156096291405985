import React from 'react';

import { useQueryLTIv1p1s } from 'gql/ltiv1p1';
import List from './List';

const ListContainer = () => {
  const { data } = useQueryLTIv1p1s();
  return <List data={data?.ltisv1p1} />;
};

export default ListContainer;
