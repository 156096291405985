import React from 'react';

import { useQueryTNECanopes } from 'gql/tnecanope';

import List from './List';

const ListContainer = () => {
  const { data } = useQueryTNECanopes();
  return <List data={data?.TNECanopes} />;
};

export default ListContainer;
