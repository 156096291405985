import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row, Spin } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import Upsert from '../Upsert';

const Update = ({ handleUpdate, data }) =>
  !data ? (
    <Spin />
  ) : (
    <>
      <Row gutter={16}>
        <Col>
          <Link to={`/auth/edugain/read/${data.id}`}>
            <Button icon={<ArrowLeftOutlined />}>Back</Button>
          </Link>
        </Col>
        <Col flex="auto">
          <h1>{`Update an eduGAIN strategy - ${data.name}`}</h1>
        </Col>
      </Row>
      <Divider />
      <Upsert data={data} handleSubmit={handleUpdate} />
    </>
  );

Update.propTypes = {
  data: PropTypes.object,
  handleUpdate: PropTypes.func.isRequired,
};

Update.defaultProps = {
  data: undefined,
};

export default Update;
