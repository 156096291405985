import { LoginOutlined } from '@ant-design/icons';
import { Empty, Button } from 'antd';
import React from 'react';

const Auth = () => (
  <div className="Auth-container">
    <Empty
      image="/images/logo.svg"
      imageStyle={{
        height: 200,
      }}
      description=""
    >
      <Button
        icon={<LoginOutlined />}
        href={`${process.env.REACT_APP_API_URL}/api/auth/internal/login`}
        size="large"
        type="primary"
      >
        Authenticate
      </Button>
    </Empty>
  </div>
);

export default Auth;
