import React from 'react';
import { useParams } from 'react-router-dom';

import { useQueryMoodle } from 'gql/moodle';

import Read from './Read';

const ReadContainer = () => {
  const { id } = useParams();
  const { data } = useQueryMoodle({
    variables: {
      moodleId: id,
    },
  });
  return <Read data={data?.moodle} />;
};

export default ReadContainer;
