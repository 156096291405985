import React from 'react';

import { useQueryOrganizations } from 'gql/organization';

import Upsert from './Upsert';

const UpsertContainer = (props) => {
  const { data } = useQueryOrganizations();
  return <Upsert organizations={data?.organizations} {...props} />;
};

export default UpsertContainer;
