import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useQueryLTIv1p3, useMutationLTIv1p3Update } from 'gql/ltiv1p3';

import Update from './Update';

const UpdateContainer = () => {
  const history = useHistory();
  const { id } = useParams();
  const { data } = useQueryLTIv1p3({
    variables: {
      ltiId: id,
    },
  });
  const mutationLTIv1p3Update = useMutationLTIv1p3Update();
  const handleUpdate = useCallback(
    async (values) => {
      try {
        const authorizationConfig = {
          method: 'JWK_SET',
          key: values.authorizationConfigKey,
        };
        await mutationLTIv1p3Update({
          ...values,
          authorizationConfig,
          ltiId: data.ltiv1p3.id,
        });
        message.success('The LTI service has been updated.');
        history.push(`/lms/lti/v1p3/read/${data.ltiv1p3.id}`);
      } catch (err) {
        message.error('An error occurred while updating the LTI service.');
      }
    },
    [data, history, mutationLTIv1p3Update],
  );
  return <Update data={data?.ltiv1p3} handleUpdate={handleUpdate} />;
};

export default UpdateContainer;
