const DESTINATIONS = [
  {
    key: 'other',
    translation: 'Other',
  },
  {
    key: 'college',
    translation: 'College',
  },
  {
    key: 'high-school',
    translation: 'High school',
  },
  {
    key: 'higher-education',
    translation: 'High education',
  },
  {
    key: 'professional-training',
    translation: 'Professional training',
  },
];

export default DESTINATIONS;
