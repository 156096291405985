import React from 'react';

import { useQueryPlans } from 'gql/plan';
import { useQueryOrganizations } from 'gql/organization';

import Upsert from './Upsert';

const UpsertContainer = (props) => {
  const { data: dataOrganizations } = useQueryOrganizations();
  const { data: dataPlans } = useQueryPlans();
  return (
    <Upsert
      organizations={dataOrganizations?.organizations}
      plans={dataPlans?.plans}
      {...props}
    />
  );
};

export default UpsertContainer;
