const LANGUAGES = [
  {
    key: 'fr',
    translation: 'French',
  },
  {
    key: 'en',
    translation: 'English',
  },
  {
    key: 'nl',
    translation: 'Dutch',
  },
];

export default LANGUAGES;
