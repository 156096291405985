import { SendOutlined } from '@ant-design/icons';
import { Button, Form, Select, Switch } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import CATEGORIES from 'contants/categories';
import DESTINATIONS from 'contants/destinations';
import LANGUAGES from 'contants/languages';

const Upsert = ({ data, form, handleSubmit }) => (
  <Form
    form={form}
    initialValues={{
      isPublic: data.isPublic || true,
      category: data.category || '',
      language: data.language || '',
      destination: data.destination || '',
      tags: data.tags.map((tag) => tag.title) || [],
    }}
    layout="vertical"
    onFinish={handleSubmit}
  >
    <Form.Item label="is public?" name="isPublic" valuePropName="checked">
      <Switch />
    </Form.Item>
    <Form.Item label="Category" name="category">
      <Select>
        {CATEGORIES.map((category) => (
          <Select.Option value={category.key}>
            {category.translation}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
    <Form.Item label="Language" name="language">
      <Select>
        {LANGUAGES.map((language) => (
          <Select.Option value={language.key}>
            {language.translation}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
    <Form.Item label="Destination" name="destination">
      <Select>
        {DESTINATIONS.map((destination) => (
          <Select.Option value={destination.key}>
            {destination.translation}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
    <Form.Item label="Tags" name="tags">
      <Select mode="tags" />
    </Form.Item>
    <Button htmlType="submit" icon={<SendOutlined />} type="primary">
      Submit
    </Button>
  </Form>
);

Upsert.propTypes = {
  data: PropTypes.object,
  form: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

Upsert.defaultProps = {
  data: {},
};

export default Upsert;
