import {
  ArrowLeftOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  EditOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import {
  Badge,
  Button,
  Col,
  Descriptions,
  Divider,
  Row,
  Space,
  Spin,
  Tag,
} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const Read = ({ data }) =>
  !data ? (
    <Spin />
  ) : (
    <>
      <Row gutter={16}>
        <Col>
          <Link to="/accounts">
            <Button icon={<ArrowLeftOutlined />}>Back</Button>
          </Link>
        </Col>
        <Col flex="auto">
          <h1>{`View an account - ${data.email}`}</h1>
        </Col>
        <Col>
          <Space>
            <Link to={`/accounts/update/${data.id}`}>
              <Button icon={<EditOutlined />} type="primary">
                Update
              </Button>
            </Link>
          </Space>
        </Col>
      </Row>
      <Divider />
      <Descriptions bordered column={1}>
        <Descriptions.Item label="Full name">{`${data.firstName} ${data.lastName}`}</Descriptions.Item>
        <Descriptions.Item label="Email">{data.email}</Descriptions.Item>
        <Descriptions.Item label="Username">{data.username}</Descriptions.Item>
        <Descriptions.Item label="Authentication method">
          {data.authMethods.map((authMethod, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Tag key={index}>{authMethod.strategy}</Tag>
          ))}
        </Descriptions.Item>
        <Descriptions.Item label="Is verified?">
          {data.isVerified ? (
            <Tag icon={<CheckCircleOutlined />} color="success">
              Yes
            </Tag>
          ) : (
            <Tag icon={<CloseCircleOutlined />} color="error">
              No
            </Tag>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Tags">
          {data.tags.map((tag, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Tag key={index}>{tag}</Tag>
          ))}
        </Descriptions.Item>
        <Descriptions.Item label="Wants marketing?">
          {data.wantsMarketing ? (
            <Tag icon={<CheckCircleOutlined />} color="success">
              Yes
            </Tag>
          ) : (
            <Tag icon={<CloseCircleOutlined />} color="error">
              No
            </Tag>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Language">{data.language}</Descriptions.Item>
        <Descriptions.Item label="Sector">{data.sector}</Descriptions.Item>
        <Descriptions.Item label="Role">{data.role}</Descriptions.Item>
        <Descriptions.Item label="Institution">
          {data.institution}
        </Descriptions.Item>
        <Descriptions.Item label="Branch">{data.branch}</Descriptions.Item>
        <Descriptions.Item label="Address">
          <p>{`${data.firstName} ${data.lastName}`}</p>
          <p>{data.addressLine1}</p>
          <p>{data.addressLine2}</p>
          <p>{`${data.zip} ${data.city}`}</p>
          <p>{`${data.state} ${data.country}`}</p>
          <p>{`${data.company} ${data.vat}`}</p>
        </Descriptions.Item>
        <Descriptions.Item label="Plan">
          {data.plan ? (
            <Link to={`/plans/read/${data.plan.id}`}>
              <Button icon={<LinkOutlined />} type="dashed">
                {data.plan.name}
              </Button>
            </Link>
          ) : (
            'Not attached to any plan'
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Organization">
          {data.organization ? (
            <Link to={`/organizations/read/${data.organization.id}`}>
              <Button icon={<LinkOutlined />} type="dashed">
                {data.organization.name}
              </Button>
            </Link>
          ) : (
            'Not attached to any organization'
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Email notifications">
          <Tag color={data.emailNotifications.comments ? 'green' : 'red'}>
            Comments
          </Tag>
          <Tag color={data.emailNotifications.invitations ? 'green' : 'red'}>
            Invitations
          </Tag>
          <Tag
            color={data.emailNotifications.questionPropose ? 'green' : 'red'}
          >
            Question proposal
          </Tag>
          <Tag color={data.emailNotifications.expertFeedback ? 'green' : 'red'}>
            Expert feedback
          </Tag>
          <Tag color={data.emailNotifications.courseProgress ? 'green' : 'red'}>
            Course Progress
          </Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Push notifications">
          <Tag color={data.pushNotifications.comments ? 'green' : 'red'}>
            Comments
          </Tag>
          <Tag color={data.pushNotifications.invitations ? 'green' : 'red'}>
            Invitations
          </Tag>
          <Tag color={data.pushNotifications.questionPropose ? 'green' : 'red'}>
            Question proposal
          </Tag>
          <Tag color={data.pushNotifications.expertFeedback ? 'green' : 'red'}>
            Expert feedback
          </Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Push token">
          {data.pushToken}
        </Descriptions.Item>
        <Descriptions.Item label="Metadata">
          <pre>{JSON.stringify(data.metadata, null, 2)}</pre>
        </Descriptions.Item>
        <Descriptions.Item label="Referrals">
          <p>
            <Badge
              count={data.referrals.filter((r) => r.isVerified).length}
              overflowCount={999999}
            />
          </p>
          {data.referrals.map((referral) => (
            <Tag
              color={referral.isVerified ? 'geekblue' : 'red'}
              icon={
                referral.isVerified ? (
                  <CheckCircleOutlined />
                ) : (
                  <CloseCircleOutlined />
                )
              }
              key={referral.id}
            >
              {referral.username}
            </Tag>
          ))}
        </Descriptions.Item>
        <Descriptions.Item label="Job">{data.job}</Descriptions.Item>
        <Descriptions.Item label="Biography">
          {data.biography}
        </Descriptions.Item>
        <Descriptions.Item label="Twitter">{data.twitter}</Descriptions.Item>
        <Descriptions.Item label="Linkedin">{data.linkedin}</Descriptions.Item>
        <Descriptions.Item label="Is ambassador?">
          {data.isAmbassador ? (
            <Tag icon={<CheckCircleOutlined />} color="success">
              Yes
            </Tag>
          ) : (
            <Tag icon={<CloseCircleOutlined />} color="error">
              No
            </Tag>
          )}
        </Descriptions.Item>
      </Descriptions>
    </>
  );

Read.propTypes = {
  data: PropTypes.object,
};

Read.defaultProps = {
  data: undefined,
};

export default Read;
