import React from 'react';

import { useQueryGARInstitutions } from 'gql/gar';

import ListInstitutions from './ListInstitutions';

const ListInstitutionsContainer = () => {
  const { data } = useQueryGARInstitutions();
  return <ListInstitutions data={data?.GARInstitutions} />;
};

export default ListInstitutionsContainer;
