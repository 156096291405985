import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useMutationMoodleUpdate, useQueryMoodle } from 'gql/moodle';

import Update from './Update';

const UpdateContainer = () => {
  const history = useHistory();
  const { id } = useParams();
  const { data } = useQueryMoodle({
    variables: {
      moodleId: id,
    },
  });
  const mutationMoodleUpdate = useMutationMoodleUpdate();
  const handleUpdate = useCallback(
    async (values) => {
      try {
        await mutationMoodleUpdate({
          ...values,
          moodleId: data.moodle.id,
        });
        message.success('The Moodle service has been updated.');
        history.push(`/lms/moodle/read/${data.moodle.id}`);
      } catch (err) {
        message.error('An error occurred while updating the Moodle service.');
      }
    },
    [data, history, mutationMoodleUpdate],
  );
  return <Update data={data?.moodle} handleUpdate={handleUpdate} />;
};

export default UpdateContainer;
