import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Popover, Row, Table } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { sortText, searchText } from 'lib/table';

const columns = [
  {
    title: 'Name',
    dataIndex: 'appellation_officielle',
    key: 'appellation_officielle',
    ...sortText('appellation_officielle'),
    ...searchText('appellation_officielle'),
  },
  {
    title: 'Institution ID',
    dataIndex: 'uai',
    key: 'uai',
    ...sortText('uai'),
    ...searchText('uai'),
  },
  {
    title: 'Data',
    key: 'data',
    // eslint-disable-next-line react/prop-types
    render: (data) => (
      <Popover
        content={<pre>{JSON.stringify(data, null, 2)}</pre>}
        title="Information"
        trigger="click"
      >
        <Button>View more</Button>
      </Popover>
    ),
  },
];

const ListInstitutions = ({ data }) => (
  <>
    <Row gutter={16}>
      <Col>
        <Link to="/partners/gar">
          <Button icon={<ArrowLeftOutlined />}>Back</Button>
        </Link>
      </Col>
      <Col flex="auto">
        <h1>List all GAR institutions</h1>
      </Col>
    </Row>
    <Divider />
    <Table columns={columns} dataSource={data} loading={!data} />
  </>
);

ListInstitutions.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

ListInstitutions.defaultProps = {
  data: undefined,
};

export default ListInstitutions;
