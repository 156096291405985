import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Popover, Row, Table } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import {
  sortText,
  searchText,
  renderArray,
  searchArray,
  renderDate,
  sortDate,
} from 'lib/table';

const columns = [
  {
    title: 'Comment',
    dataIndex: 'commentaireAbonnement',
    key: 'commentaireAbonnement',
    ...sortText('commentaireAbonnement'),
    ...searchText('commentaireAbonnement'),
  },
  {
    title: 'Institution IDs',
    dataIndex: 'uaiEtab',
    key: 'uaiEtab',
    ...searchArray('uaiEtab'),
    ...renderArray('uaiEtab'),
  },
  {
    title: 'Start date',
    dataIndex: 'debutValidite',
    key: 'debutValidite',
    ...renderDate('debutValidite'),
    ...sortDate('debutValidite'),
    ...searchText('debutValidite'),
  },
  {
    title: 'Stop date',
    dataIndex: 'finValidite',
    key: 'finValidite',
    ...renderDate('finValidite'),
    ...sortDate('finValidite'),
    ...searchText('finValidite'),
  },
  {
    title: '# Licenses',
    dataIndex: 'nbLicenceGlobale',
    key: 'nbLicenceGlobale',
    ...sortText('nbLicenceGlobale'),
    ...searchText('nbLicenceGlobale'),
  },
  {
    title: 'Data',
    key: 'data',
    // eslint-disable-next-line react/prop-types
    render: (data) => (
      <Popover
        content={<pre>{JSON.stringify(data, null, 2)}</pre>}
        title="Information"
        trigger="click"
      >
        <Button>View more</Button>
      </Popover>
    ),
  },
];

const ListSubscriptions = ({ data }) => (
  <>
    <Row gutter={16}>
      <Col>
        <Link to="/partners/gar">
          <Button icon={<ArrowLeftOutlined />}>Back</Button>
        </Link>
      </Col>
      <Col flex="auto">
        <h1>List all GAR remote subscriptions</h1>
      </Col>
    </Row>
    <Divider />
    <Table columns={columns} dataSource={data} loading={!data} />
  </>
);

ListSubscriptions.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

ListSubscriptions.defaultProps = {
  data: undefined,
};

export default ListSubscriptions;
