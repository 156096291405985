import React from 'react';

import { useQueryCoupons } from 'gql/coupon';
import List from './List';

const ListContainer = () => {
  const { data } = useQueryCoupons();
  return <List data={data?.coupons} />;
};

export default ListContainer;
