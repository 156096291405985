import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

const AUTH_RECOVER_PASSWORD = gql`
  mutation AuthRecoverPassword($email: String!) {
    authRecoverPassword(email: $email)
  }
`;

export function useMutationAuthRecoverPassword() {
  const [mutation] = useMutation(AUTH_RECOVER_PASSWORD);
  return useCallback((variables) => mutation({ variables }), [mutation]);
}

const AUTH_RESEND_VERIFICATION = gql`
  mutation AuthResendVerification($email: String!) {
    authResendVerification(email: $email)
  }
`;

export function useMutationAuthResendVerification() {
  const [mutation] = useMutation(AUTH_RESEND_VERIFICATION);
  return useCallback((variables) => mutation({ variables }), [mutation]);
}
