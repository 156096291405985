import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useMutationCASDelete, useQueryCAS } from 'gql/cas';

import Delete from './Delete';

const DeleteContainer = () => {
  const history = useHistory();
  const { id } = useParams();
  const { data } = useQueryCAS({
    variables: {
      casId: id,
    },
  });
  const mutationCASDelete = useMutationCASDelete();
  const handleDelete = useCallback(async () => {
    try {
      await mutationCASDelete({
        casId: data.CAS.id,
      });
      message.success('The CAS strategy has been deleted.');
      history.push('/auth/cas');
    } catch (err) {
      message.error('An error occurred while deleting the CAS strategy.');
    }
  }, [data, history, mutationCASDelete]);
  return <Delete data={data?.CAS} handleDelete={handleDelete} />;
};

export default DeleteContainer;
