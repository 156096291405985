import { message } from 'antd';
import queryString from 'query-string';
import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import storeJS from 'store';

import Context from 'lib/context';

import Auth from './Auth';

const AuthContainer = () => {
  const { handleLogIn } = useContext(Context);
  const history = useHistory();
  const { search } = useLocation();
  const query = queryString.parse(search);
  useEffect(() => {
    if (query.token) {
      storeJS.set('token', query.token);
      handleLogIn();
      history.replace('/');
      message.success('You are logged in!');
    }
  }, [handleLogIn, history, query.token]);
  return <Auth />;
};

export default AuthContainer;
