import React from 'react';

import { useQuerySSOs } from 'gql/sso';

import Active from './Active';

const ActiveContainer = () => {
  const { data } = useQuerySSOs();
  return <Active data={data?.SSOs} />;
};

export default ActiveContainer;
