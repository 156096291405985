import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useMutationLTIv1p1Create } from 'gql/ltiv1p1';

import Create from './Create';

const CreateContainer = () => {
  const history = useHistory();
  const mutationLTIv1p1Create = useMutationLTIv1p1Create();
  const handleCreate = useCallback(
    async (values) => {
      try {
        const { data } = await mutationLTIv1p1Create(values);
        message.success('The LTI service has been created.');
        history.push(`/lms/lti/v1p1/read/${data.ltiv1p1Create.id}`);
      } catch (err) {
        message.error('An error occurred while creating the LTI service.');
      }
    },
    [history, mutationLTIv1p1Create],
  );
  return <Create handleCreate={handleCreate} />;
};

export default CreateContainer;
