import {
  DownloadOutlined,
  EyeOutlined,
  PlusOutlined,
  ReloadOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Button, Col, Divider, Row, Space, Table } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import storeJS from 'store';

import {
  sortText,
  searchText,
  sortBool,
  searchBool,
  renderBool,
} from 'lib/table';

const columns = [
  {
    title: 'Key',
    dataIndex: 'key',
    key: 'key',
    ...sortText('key', 1),
    ...searchText('key'),
  },
  {
    title: 'English',
    dataIndex: 'en',
    key: 'en',
    ellipsis: true,
    ...sortText('en', 1),
    ...searchText('en'),
  },
  {
    title: 'French',
    dataIndex: 'fr',
    key: 'fr',
    ellipsis: true,
    ...sortText('fr', 2),
    ...searchText('fr'),
  },
  {
    title: 'Dutch',
    dataIndex: 'nl',
    key: 'nl',
    ellipsis: true,
    ...sortText('nl', 3),
    ...searchText('nl'),
  },
  {
    title: 'Italian',
    dataIndex: 'it',
    key: 'it',
    ellipsis: true,
    ...sortText('it', 3),
    ...searchText('it'),
  },
  {
    title: 'Is flagged?',
    dataIndex: 'isFlagged',
    key: 'isFlagged',
    width: 160,
    ...sortBool('isFlagged', 4),
    ...searchBool('isFlagged'),
    ...renderBool(),
  },
  {
    title: 'Is synced?',
    dataIndex: 'isSynced',
    key: 'isSynced',
    width: 160,
    ...sortBool('isSynced', 5),
    ...searchBool('isSynced'),
    ...renderBool(),
  },
  {
    title: '',
    key: 'read',
    width: 100,
    // eslint-disable-next-line react/prop-types
    render: ({ id }) => (
      <Link to={`/translations/read/${id}`}>
        <Button icon={<EyeOutlined />}>View</Button>
      </Link>
    ),
  },
];

const List = ({ data }) => (
  <>
    <Row gutter={16}>
      <Col flex="auto">
        <h1>List all translations</h1>
      </Col>
      <Col>
        <Space>
          <Link to="/translations/create">
            <Button icon={<PlusOutlined />} type="primary">
              Create a new translation
            </Button>
          </Link>
          <Link to="/translations/import">
            <Button icon={<UploadOutlined />}>Import translations</Button>
          </Link>
          <a
            href={`${
              process.env.REACT_APP_API_URL
            }/rest/translations/export-all?authToken=${storeJS.get('token')}`}
          >
            <Button icon={<DownloadOutlined />}>Export translations</Button>
          </a>
          <Link to="/translations/sync">
            <Button icon={<ReloadOutlined />}>Sync the translations</Button>
          </Link>
        </Space>
      </Col>
    </Row>
    <Divider />
    <Table columns={columns} dataSource={data} loading={!data} />
  </>
);

List.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};

List.defaultProps = {
  data: undefined,
};

export default List;
