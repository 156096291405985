import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useMutationCASCreate } from 'gql/cas';

import Create from './Create';

const CreateContainer = () => {
  const history = useHistory();
  const mutationCASCreate = useMutationCASCreate();
  const handleCreate = useCallback(
    async ({ profile, configuration, ...values }) => {
      try {
        const { data } = await mutationCASCreate({
          ...values,
          profile: JSON.parse(profile),
          configuration: JSON.parse(configuration),
        });
        message.success('The CAS strategy has been created.');
        history.push(`/auth/cas/read/${data.casCreate.id}`);
      } catch (err) {
        message.error('An error occurred while creating the CAS strategy.');
      }
    },
    [history, mutationCASCreate],
  );
  return <Create handleCreate={handleCreate} />;
};

export default CreateContainer;
