import { gql, useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';

const QUERY_PLANS = gql`
  {
    plans {
      id
      name
    }
  }
`;

export function useQueryPlans() {
  const { loading, data, ...props } = useQuery(QUERY_PLANS);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

const QUERY_PLAN = gql`
  query plan($planId: ID!) {
    plan(planId: $planId) {
      id
      name
      organizations {
        id
        name
      }
      accounts {
        id
        firstName
        lastName
      }
    }
  }
`;

export function useQueryPlan(options) {
  const { loading, data, ...props } = useQuery(QUERY_PLAN, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const PLAN_CREATE = gql`
  mutation PlanCreate($name: String!) {
    planCreate(name: $name) {
      id
    }
  }
`;

export function useMutationPlanCreate() {
  const [mutation] = useMutation(PLAN_CREATE);
  return useCallback((variables) => mutation({ variables }), [mutation]);
}

export const PLAN_UPDATE = gql`
  mutation PlanUpdate($planId: ID!, $name: String) {
    planUpdate(planId: $planId, name: $name) {
      id
    }
  }
`;

export function useMutationPlanUpdate() {
  const [mutation] = useMutation(PLAN_UPDATE);
  return useCallback((variables) => mutation({ variables }), [mutation]);
}

export const PLAN_DELETE = gql`
  mutation PlanDelete($planId: ID!) {
    planDelete(planId: $planId)
  }
`;

export function useMutationPlanDelete() {
  const [mutation] = useMutation(PLAN_DELETE);
  return useCallback((variables) => mutation({ variables }), [mutation]);
}
