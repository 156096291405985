import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useMutationAccountMerge } from 'gql/account';

import Merge from './Merge';

const MergeContainer = (props) => {
  const history = useHistory();
  const mutationAccountMerge = useMutationAccountMerge();
  const handleMerge = useCallback(
    async ({ fromId, toId }) => {
      try {
        await mutationAccountMerge({
          fromId,
          toId,
        });
        message.success('The accounts have been merged.');
        history.push(`/accounts/read/${toId}`);
      } catch (err) {
        message.error('An error occurred while merging the accounts.');
      }
    },
    [history, mutationAccountMerge],
  );
  return <Merge handleMerge={handleMerge} {...props} />;
};

export default MergeContainer;
