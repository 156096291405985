import { Form } from 'antd';
import React from 'react';

import Marketing from './Marketing';

const MarketingContainer = () => {
  const [form] = Form.useForm();
  const role = Form.useWatch('role', form);
  const sector = Form.useWatch('sector', form);
  const language = Form.useWatch('language', form);
  return (
    <Marketing form={form} language={language} role={role} sector={sector} />
  );
};

export default MarketingContainer;
