import React from 'react';
import { useParams } from 'react-router-dom';

import { useQueryEduGAIN } from 'gql/edugain';

import Read from './Read';

const ReadContainer = () => {
  const { id } = useParams();
  const { data } = useQueryEduGAIN({
    variables: {
      edugainId: id,
    },
  });
  return <Read data={data?.eduGAIN} />;
};

export default ReadContainer;
