import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useMutationTranslationSync } from 'gql/translation';

import Sync from './Sync';

const SyncContainer = () => {
  const history = useHistory();
  const mutationTranslationSync = useMutationTranslationSync();
  const handleSync = useCallback(async () => {
    try {
      await mutationTranslationSync();
      message.success('The translations have been synced.');
      history.push('/translations');
    } catch (err) {
      message.error('An error occurred while syncing the translations.');
    }
  }, [history, mutationTranslationSync]);
  return <Sync handleSync={handleSync} />;
};

export default SyncContainer;
