import { message } from 'antd';
import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useMutationSAMLUpdate, useQuerySAML } from 'gql/saml';

import Update from './Update';

const UpdateContainer = () => {
  const history = useHistory();
  const { id } = useParams();
  const { data } = useQuerySAML({
    variables: {
      samlId: id,
    },
  });
  const mutationSAMLUpdate = useMutationSAMLUpdate();
  const handleUpdate = useCallback(
    async ({ profile, configuration, ...values }) => {
      try {
        await mutationSAMLUpdate({
          ...values,
          samlId: data.SAML.id,
          profile: JSON.parse(profile),
          configuration: JSON.parse(configuration),
        });
        message.success('The SAML strategy has been updated.');
        history.push(`/auth/saml/read/${data.SAML.id}`);
      } catch (err) {
        message.error('An error occurred while updating the SAML strategy.');
      }
    },
    [data, history, mutationSAMLUpdate],
  );
  return <Update data={data?.SAML} handleUpdate={handleUpdate} />;
};

export default UpdateContainer;
