import { SendOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const Upsert = ({ data, handleSubmit, organizations, plans }) => (
  <Form
    initialValues={{
      name: data.name || '',
      accessCode: data.accessCode || '',
      planId: data.planId || null,
      organizationId: data.organizationId || null,
    }}
    layout="vertical"
    onFinish={handleSubmit}
  >
    <Form.Item
      label="Display name of the coupon (e.g. Starter)."
      name="name"
      rules={[{ required: true }]}
    >
      <Input placeholder="Wooclap" />
    </Form.Item>
    <Form.Item
      label="Access code of the coupon (e.g. JOIN_STARTER)."
      name="accessCode"
      rules={[{ required: true }]}
    >
      <Input placeholder="Wooclap" />
    </Form.Item>
    <Form.Item label="Plan associated with the coupon." name="planId">
      <Select
        allowClear
        showSearch
        filterOption={(input, option) =>
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        options={[
          {
            label: 'Not attached to any plan',
            value: null,
          },
          ...plans.map((plan) => ({
            label: plan.name,
            value: plan.id,
          })),
        ]}
      />
    </Form.Item>
    <Form.Item
      label="Organization associated with the coupon."
      name="organizationId"
    >
      <Select
        allowClear
        showSearch
        filterOption={(input, option) =>
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        options={[
          {
            label: 'Not attached to any organization',
            value: null,
          },
          ...organizations.map((organization) => ({
            label: organization.name,
            value: organization.id,
          })),
        ]}
      />
    </Form.Item>
    <Button htmlType="submit" icon={<SendOutlined />} type="primary">
      Submit
    </Button>
  </Form>
);

Upsert.propTypes = {
  data: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  organizations: PropTypes.arrayOf(PropTypes.object),
  plans: PropTypes.arrayOf(PropTypes.object),
};

Upsert.defaultProps = {
  data: {},
  organizations: [],
  plans: [],
};

export default Upsert;
