import React from 'react';
import { useParams } from 'react-router-dom';

import { useQueryAccount } from 'gql/account';

import Read from './Read';

const ReadContainer = () => {
  const { id } = useParams();
  const { data } = useQueryAccount({
    variables: {
      accountId: id,
    },
  });
  return <Read data={data?.account} />;
};

export default ReadContainer;
