import { SendOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select, Switch } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const Upsert = ({ data, handleSubmit, organizations }) => (
  <Form
    initialValues={{
      organizationId: data.organizationId || null,
      adminEmail: data.adminEmail || '',
      isPublic: data.isPublic || false,
      overrides: data.overrides ? JSON.stringify(data.overrides, null, 2) : '',
    }}
    layout="vertical"
    onFinish={handleSubmit}
  >
    <Form.Item
      label="Organization associated with the eduGAIN strategy."
      name="organizationId"
    >
      <Select
        allowClear
        showSearch
        filterOption={(input, option) =>
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        options={[
          {
            label: 'Not attached to any organization',
            value: null,
          },
          ...organizations.map((organization) => ({
            label: organization.name,
            value: organization.id,
          })),
        ]}
      />
    </Form.Item>
    <Form.Item
      label="Email address of the administrator of the eduGAIN strategy (e.g. dev@wooclap.com)"
      name="adminEmail"
    >
      <Input placeholder="dev@wooclap.com" />
    </Form.Item>
    <Form.Item
      label="Make this eduGAIN strategy public and display it on the login page of Wooflash."
      name="isPublic"
      valuePropName="checked"
    >
      <Switch />
    </Form.Item>
    <Form.Item
      label="Configuration overrides. This string is converted to a JSON object. Can contain name, domains, and profile."
      name="overrides"
      rules={[{ required: true }]}
    >
      <Input.TextArea
        autoSize
        placeholder='{ "name": "", "domains": "", "profile": "" }'
      />
    </Form.Item>
    <Button htmlType="submit" icon={<SendOutlined />} type="primary">
      Submit
    </Button>
  </Form>
);

Upsert.propTypes = {
  data: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  organizations: PropTypes.arrayOf(PropTypes.object),
};

Upsert.defaultProps = {
  data: {},
  organizations: [],
};

export default Upsert;
