import { gql, useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';

const QUERY_GARS = gql`
  {
    GARs {
      id
      organizationId
      isTNE
      name
      institutionIds
      comment
      startDate
      stopDate
      licenseType
      nbLicenses
      organization {
        id
        name
      }
      codeProjetRessource
    }
  }
`;

export function useQueryGARs() {
  const { loading, data, ...props } = useQuery(QUERY_GARS);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

const QUERY_GAR = gql`
  query gar($garId: ID!) {
    GAR(garId: $garId) {
      id
      organizationId
      isTNE
      name
      institutionIds
      comment
      startDate
      stopDate
      licenseType
      nbLicenses
      organization {
        id
        name
      }
      codeProjetRessource
    }
  }
`;

export function useQueryGAR(options) {
  const { loading, data, ...props } = useQuery(QUERY_GAR, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

const QUERY_GAR_INSTITUTIONS = gql`
  {
    GARInstitutions
  }
`;

export function useQueryGARInstitutions() {
  const { loading, data, ...props } = useQuery(QUERY_GAR_INSTITUTIONS);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

const QUERY_GAR_SUBSCRIPTIONS = gql`
  {
    GARSubscriptions
  }
`;

export function useQueryGARSubscriptions() {
  const { loading, data, ...props } = useQuery(QUERY_GAR_SUBSCRIPTIONS);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const GAR_CREATE = gql`
  mutation GARCreate(
    $organizationId: ID
    $isTNE: Boolean!
    $name: String!
    $institutionId: String!
    $comment: String
    $startDate: Date!
    $stopDate: Date!
    $licenseType: String!
    $nbLicenses: Int!
    $codeProjetRessource: String
  ) {
    GARCreate(
      organizationId: $organizationId
      isTNE: $isTNE
      name: $name
      institutionId: $institutionId
      comment: $comment
      startDate: $startDate
      stopDate: $stopDate
      licenseType: $licenseType
      nbLicenses: $nbLicenses
      codeProjetRessource: $codeProjetRessource
    ) {
      id
    }
  }
`;

export function useMutationGARCreate() {
  const [mutation] = useMutation(GAR_CREATE);
  return useCallback((variables) => mutation({ variables }), [mutation]);
}

export const GAR_UPDATE = gql`
  mutation GARUpdate(
    $garId: ID!
    $organizationId: ID
    $isTNE: Boolean
    $name: String
    $comment: String
    $startDate: Date
    $stopDate: Date
    $licenseType: String
    $nbLicenses: Int
    $codeProjetRessource: String
  ) {
    GARUpdate(
      garId: $garId
      organizationId: $organizationId
      isTNE: $isTNE
      name: $name
      comment: $comment
      startDate: $startDate
      stopDate: $stopDate
      licenseType: $licenseType
      nbLicenses: $nbLicenses
      codeProjetRessource: $codeProjetRessource
    ) {
      id
    }
  }
`;

export function useMutationGARUpdate() {
  const [mutation] = useMutation(GAR_UPDATE);
  return useCallback((variables) => mutation({ variables }), [mutation]);
}

export const GAR_DELETE = gql`
  mutation GARDelete($garId: ID!) {
    GARDelete(garId: $garId)
  }
`;

export function useMutationGARDelete() {
  const [mutation] = useMutation(GAR_DELETE);
  return useCallback((variables) => mutation({ variables }), [mutation]);
}
