import React from 'react';
import { useParams } from 'react-router-dom';

import { useQueryPublicCourse } from 'gql/course';

import Read from './Read';

const ReadContainer = () => {
  const { id } = useParams();
  const { data, loading } = useQueryPublicCourse({
    variables: {
      courseId: id,
    },
  });
  return <Read data={data?.publicCourse} loading={loading} />;
};

export default ReadContainer;
