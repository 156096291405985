import { KeyOutlined, MailOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Divider, Row, Space } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => (
  <>
    <Row gutter={16}>
      <Col flex="auto">
        <h1>Local</h1>
      </Col>
      <Col>
        <Space>
          <Link to="/auth/local/password">
            <Button icon={<KeyOutlined />}>
              Send a password recovery email
            </Button>
          </Link>
          <Link to="/auth/local/verification">
            <Button icon={<MailOutlined />}>Resend a verification email</Button>
          </Link>
        </Space>
      </Col>
    </Row>
    <Divider />
    <p>
      <Alert
        message="Information"
        description="Users that register using their email address are required to verify their account using a verification that they receive by email."
        type="info"
        showIcon
      />
    </p>
  </>
);

export default Home;
