import React from 'react';

import { useQueryPublicCourses } from 'gql/course';

import List from './List';

const ListContainer = () => {
  const { data } = useQueryPublicCourses();
  return <List data={data?.publicCourses} />;
};

export default ListContainer;
