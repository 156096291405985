import { gql, useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';

const QUERY_SAMLS = gql`
  {
    SAMLs {
      id
      organizationId
      name
      specifier
      domains
      isPublic
      organization {
        id
        name
      }
    }
  }
`;

export function useQuerySAMLs() {
  const { loading, data, ...props } = useQuery(QUERY_SAMLS);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

const QUERY_SAML = gql`
  query SAML($samlId: ID!) {
    SAML(samlId: $samlId) {
      id
      organizationId
      name
      specifier
      domains
      adminEmail
      profile
      configuration
      metadata
      isPublic
      organization {
        id
        name
      }
    }
  }
`;

export function useQuerySAML(options) {
  const { loading, data, ...props } = useQuery(QUERY_SAML, options);
  return {
    isLoading: loading || !data,
    loading,
    data,
    ...props,
  };
}

export const SAML_CREATE = gql`
  mutation samlCreate(
    $organizationId: ID
    $name: String!
    $specifier: String!
    $domains: [String!]!
    $adminEmail: String
    $profile: JSON!
    $configuration: JSON!
    $metadata: String!
    $isPublic: Boolean
  ) {
    samlCreate(
      organizationId: $organizationId
      name: $name
      specifier: $specifier
      domains: $domains
      adminEmail: $adminEmail
      profile: $profile
      configuration: $configuration
      metadata: $metadata
      isPublic: $isPublic
    ) {
      id
    }
  }
`;

export function useMutationSAMLCreate() {
  const [mutation] = useMutation(SAML_CREATE);
  return useCallback((variables) => mutation({ variables }), [mutation]);
}

export const SAML_UPDATE = gql`
  mutation samlUpdate(
    $samlId: ID!
    $organizationId: ID
    $name: String
    $specifier: String
    $domains: [String!]
    $adminEmail: String
    $profile: JSON
    $configuration: JSON
    $metadata: String
    $isPublic: Boolean
  ) {
    samlUpdate(
      samlId: $samlId
      organizationId: $organizationId
      name: $name
      specifier: $specifier
      domains: $domains
      adminEmail: $adminEmail
      profile: $profile
      configuration: $configuration
      metadata: $metadata
      isPublic: $isPublic
    ) {
      id
    }
  }
`;

export function useMutationSAMLUpdate() {
  const [mutation] = useMutation(SAML_UPDATE);
  return useCallback((variables) => mutation({ variables }), [mutation]);
}

export const SAML_DELETE = gql`
  mutation samlDelete($samlId: ID!) {
    samlDelete(samlId: $samlId)
  }
`;

export function useMutationSAMLDelete() {
  const [mutation] = useMutation(SAML_DELETE);
  return useCallback((variables) => mutation({ variables }), [mutation]);
}
