import { DownloadOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Row, Select } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import storeJS from 'store';

const Marketing = ({ form, language, role, sector }) => (
  <>
    <Row>
      <h1>Download users information for marketing purposes</h1>
    </Row>
    <Divider />
    <Row>
      <Form
        form={form}
        initialValues={{
          role: 'all',
          language: 'all',
          sector: 'all',
        }}
        layout="vertical"
      >
        <Form.Item
          label="Choose the type of user that you want to target"
          name="role"
        >
          <Select
            showSearch
            options={[
              {
                label: 'all users',
                value: 'all',
              },
              {
                label: 'Teachers',
                value: 'teacher',
              },
              {
                label: 'Students',
                value: 'student',
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Choose the sector of the users that you want to target"
          name="sector"
        >
          <Select
            showSearch
            options={[
              {
                label: 'all sectors',
                value: 'all',
              },
              {
                label: 'Lower education (college and highschool)',
                value: 'lower',
              },
              {
                label: 'Higher education',
                value: 'higher',
              },
              {
                label: 'Business',
                value: 'business',
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Choose the language of the users that you want to target"
          name="language"
        >
          <Select
            showSearch
            options={[
              {
                label: 'all languages',
                value: 'all',
              },
              {
                label: 'French',
                value: 'fr',
              },
              {
                label: 'English',
                value: 'en',
              },
              {
                label: 'Dutch',
                value: 'nl',
              },
            ]}
          />
        </Form.Item>
        <Button
          href={`${
            process.env.REACT_APP_API_URL
          }/rest/users/export-csv?role=${role}&sector=${sector}&language=${language}&authToken=${storeJS.get(
            'token',
          )}`}
          icon={<DownloadOutlined />}
          type="primary"
        >
          Download
        </Button>
      </Form>
    </Row>
  </>
);

Marketing.propTypes = {
  form: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  sector: PropTypes.string.isRequired,
};

export default Marketing;
